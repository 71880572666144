import React, { useEffect, useState } from "react";
import locIcon from "../assets/image/contact/fluent_location-48-filled.png";
import emailIcon from "../assets/image/contact/dashicons_email-alt.png";
import { submitForm } from "../Actions/ContactActions";
import * as Yup from "yup";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import useStyles from "./Calculation/BatteryLife/CalculatorStyle";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactLoading from "react-loading";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const contactSchema = Yup.object({
    first_name: Yup.string().required("First Name is required").trim(),
    last_name: Yup.string().required("Last Name is required").trim(),
    company_name: Yup.string().required("Company is required").trim(),
    country: Yup.string().required("Country is required").trim(),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required")
      .trim(),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    country: "",
    email: "",
    message: "",
  };

  const submit = async (values) => {
    setLoading(true);

    const object = {
      first_name: values.first_name,
      last_name: values.last_name,
      company_name: values.company_name,
      country: values.country,
      email: values.email,
      message: values.message,
      type_of_inquiry: "Sales Question",
    };

    const result = await submitForm(object)
      .then((response) => {
        setModalOpen(true);
        setMessage("Email sent successfully");
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        setModalOpen(true);
        setMessage("Please try again!");
        console.log("error contact form", error);
      });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setModalOpen(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <div className="inner-pages-banner">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-10">
              <div className="section-heading-14 text-center text-lg-start">
                <h2>Get in Touch</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-contact-form-area-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12089.254210033134!2d-73.99492953175817!3d40.75512787932722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259ab29218b1d%3A0x34803c530c7340ec!2s1460%20Broadway%2C%20New%20York%2C%20NY%2010036!5e0!3m2!1sen!2sus!4v1634711667506!5m2!1sen!2sus"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="inner-con-form-card-1">
                <div className="inner-contact-form-1">
                  {/* contact us form */}
                  {/* <squarespace:block-field
                    id="blockField4"
                    columns="12"
                    lock-layout="false"
                  /> */}

                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { resetForm }) => {
                      // Your submission logic here
                      submit(values);
                      
                      // Reset the form after submission
                      resetForm();
                    }}
                    validationSchema={contactSchema}
                  >
                    {(props) => (
                      <form
                      onSubmit={props.handleSubmit}
                        style={{ justifyContent: "center" }}
                      >
                        {loading ? (
                          <div
                            style={{
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <ReactLoading type={"spin"} color={"#2aace3"} />
                          </div>
                        ) : (
                          <>
                            <div className={classes.textFieldView}>
                              <div
                                className="d-flex container"
                                style={{ alignItems: "center" }}
                              >
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.first_name}
                                  name="first_name"
                                  label="First Name"
                                  sx={{
                                    m: 1,
                                    width: "22ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.last_name}
                                  name="last_name"
                                  label="Last Name"
                                  sx={{
                                    m: 1,
                                    width: "22ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            {props.errors.last_name &&
                              props.errors.last_name && (
                                <p className={classes.errorTextContact}>
                                  Please enter your full name
                                </p>
                              )}
                            <div className={classes.textFieldView}>
                              <div
                                className="d-flex container"
                                style={{ alignItems: "center" }}
                              >
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.company_name}
                                  name="company_name"
                                  label="Company"
                                  sx={{
                                    m: 1,
                                    width: "45ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            {props.errors.company_name && (
                              <p className={classes.errorTextContact}>
                                {props.errors.company_name}
                              </p>
                            )}
                            <div className={classes.textFieldView}>
                              <div
                                className="d-flex container"
                                style={{ alignItems: "center" }}
                              >
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.country}
                                  name="country"
                                  label="Country"
                                  sx={{
                                    m: 1,
                                    width: "45ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            {props.errors.country && (
                              <p className={classes.errorTextContact}>
                                {props.errors.country}
                              </p>
                            )}
                            <div className={classes.textFieldView}>
                              <div
                                className="d-flex container"
                                style={{ alignItems: "center" }}
                              >
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.email}
                                  name="email"
                                  label="Email"
                                  sx={{
                                    m: 1,
                                    width: "45ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            {props.errors.email && (
                              <p className={classes.errorTextContact}>
                                {props.errors.email}
                              </p>
                            )}
                            <div className={classes.textFieldView}>
                              <div
                                className="d-flex container"
                                style={{ alignItems: "center" }}
                              >
                                <TextField
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.message}
                                  multiline
                                  name="message"
                                  inputProps={{
                                    style: {
                                      height: "20ch",
                                    },
                                  }}
                                  label="Message"
                                  sx={{
                                    m: 1,
                                    width: "45ch",
                                    "& label": {
                                      // fontFamily: 'OpenSans',
                                      // right: -3,
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            {props.errors.message && (
                              <p className={classes.errorTextContact}>
                                {props.errors.message}
                              </p>
                            )}

                            <button
                              className="btn btn-style-02"
                              type="submit"
                           
                            >
                              Send
                            </button>
                          </>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-contact-2-items-area">
        <div className="container">
          <div className="row justify-content-center inner-contact-2-items-area-items">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card-area d-xs-flex">
                <div className="icon-box">
                  <img src={locIcon} />
                </div>
                <div className="content-box">
                  <h4>Visit Us</h4>
                  <p>
                    1460 Broadway, <br />
                    Suite 14034 New York,
                    <br /> NY 10036
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="card-area d-xs-flex">
                <div className="icon-box">
                  <img src={emailIcon} />
                </div>
                <div className="content-box">
                  <h4>Email us</h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:info@nanothingsinc.com"
                  >
                    <p>info@nanothingsinc.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={modalOpen}
          autoHideDuration={2000}
          onClose={() => setModalOpen(false)}
          message={message}
          action={action}
          key={vertical + horizontal}
          anchorOrigin={{ vertical, horizontal }}
        />
      </div>
    </>
  );
};

export default ContactUs;
