import React, { useState, useEffect, useContext } from "react";
import useStyles from "../BatteryLife/CalculatorStyle";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PayloadDecoder = () => {
  const classes = useStyles();
  const [finalTemps, setFinalTemps] = useState([]);
  const [tempExt, setTempExt] = useState("");
  const [tempInt, setTempInt] = useState("");
  const [voltage, setVoltage] = useState("");
  const [ackId, setAckId] = useState("");
  const [recordPeriod, setRecordPeriod] = useState("");
  const [reportPeriod, setReportPeriod] = useState("");
  const [recordUnit, setRecordUnit] = useState("");
  const [fid, setFid] = useState("");
  const [firstTimeStamp, setFirstTimeStamp] = useState("");
  const [lastSampleTimeStamp, setLastSampleTimeStamp] = useState("");
  const [lastSampleNumber, setLastSampleNumber] = useState("");
  const [momentTime, setMomentTime] = useState("");
  const [batteryStatus, setBatteryStatus] = useState("");

  const [lowerFid, setLowerFid] = useState("");
  const [upperFid, setUpperFid] = useState("");

  const [allFid, setAllFid] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [adrValue, setAdrValue] = useState("");
  const [confirmedValue, setConfirmedValue] = useState("");

  const [networkSwitch, setNetworkSwitch] = useState("");
  const [joinEUI, setJoinEUI] = useState("");
  const [US915SubBand, setUS915SubBand] = useState("");
  const [netID, setNetID] = useState("");

  function isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
  function base64ToHex(str) {
    if (isBase64(str)) {
      if (str.length <= 1) {
        setPayLoad("");
        //Decoder('', port, payloadSelectedType, str)
      } else {
        const raw = atob(str);

        let result = "";
        for (let i = 0; i < raw.length; i++) {
          const hex = raw.charCodeAt(i).toString(16);
          result += hex.length === 2 ? hex : "0" + hex;
        }
        //Decoder(result.toUpperCase(), port, payloadSelectedType, str)

        setPayLoad(result.toUpperCase());

        return result.toUpperCase();
      }
    }
  }

  function hexToBase64(bytes) {
    console.log("BYTESS", bytes.length);
    if (bytes.length <= 1) {
      setBase64("");
      // Decoder(bytes, port, payloadSelectedType, "")
    } else {
      const toBase64 = btoa(
        bytes
          .match(/\w{2}/g)
          .map(function (a) {
            return String.fromCharCode(parseInt(a, 16));
          })
          .join("")
      );
      setBase64(toBase64);
      // Decoder(bytes, port, payloadSelectedType, toBase64)
    }
  }

  function Decoder(bytes, port, packetType, base64) {
    if (!bytes) {
      return;
    }
    // console.log("DECODER BYTESSSS", bytes)
    // console.log("base6444", base64)
    // console.log(base64ToHex("AwAVAKsAzQ=="));

    // setBase64(base64)
    // hexToBase64(bytes)
    // base64ToHex(base64)

    // var base64String = Buffers.from(bytes, 'hex').toString('base64')
    // console.log("base64String", base64String)

    // setpayloadSelectedType(packetType)
    // console.log("packettype", packetType)

    const hexBytes = bytes.toUpperCase().match(/.{1,2}/g);
    // console.log("hexBytes", hexBytes)

    const decoded = {
      port,
    };

    switch (packetType) {
      case "uplink":
        switch (port) {
          case 1:
            decoded.uplinkType = "boot_message";
            decoded.voltageMv = parseInt(hexBytes.slice(2, 4).join(""), 16);
            decoded.temp_ext = parseInt(hexBytes.slice(0, 1).join(""), 16) - 50;
            decoded.temp_int = parseInt(hexBytes.slice(1, 2).join(""), 16) - 50;
            setTempInt(decoded.temp_int);
            setTempExt(decoded.temp_ext);
            setVoltage(decoded.voltageMv);
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            if (hexBytes.slice(0, 1) == "FC") {
              setErrorMessage(
                "Unable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FC)"
              );
              setShowError(true);
            } else if (hexBytes.slice(0, 1) == "FF") {
              setErrorMessage(
                "Collected temperature sample is out of operating range (Error Code FF)"
              );
              setShowError(true);
            } else {
              setErrorMessage("");
              setShowError(false);
            }
            break;
          case 13:
            decoded.uplinkType = "health_message";
            decoded.voltageMv = parseInt(hexBytes.slice(2, 4).join(""), 16);
            decoded.temp_ext = parseInt(hexBytes.slice(0, 1).join(""), 16) - 50;
            decoded.temp_int = parseInt(hexBytes.slice(1, 2).join(""), 16) - 50;
            setTempInt(decoded.temp_int);
            setTempExt(decoded.temp_ext);
            setVoltage(decoded.voltageMv);
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            if (hexBytes.slice(0, 1) == "FC") {
              setErrorMessage(
                "Unable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FC)"
              );
              setShowError(true);
            } else if (hexBytes.slice(0, 1) == "FF") {
              setErrorMessage(
                "Collected temperature sample is out of operating range (Error Code FF)"
              );
              setShowError(true);
            } else {
              setErrorMessage("");
              setShowError(false);
            }
            break;
            break;
          case 21:
            decoded.uplinkType = "device_status_response";
            decoded.voltageMv = parseInt(hexBytes.slice(0, 2).join(""), 16);
            decoded.lastSampleNumber = parseInt(
              hexBytes.slice(2, 6).join(""),
              16
            );
            decoded.lastSampleTimeStamp = parseInt(
              hexBytes.slice(6, 10).join(""),
              16
            );
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            decoded.moment = moment
              .utc(decoded.lastSampleTimeStamp * 1000)
              .format("MMMM, D YYYY, hh:mm:ss A");
            setMomentTime(decoded.moment);
            setVoltage(decoded.voltageMv);
            setLastSampleNumber(decoded.lastSampleNumber);
            setLastSampleTimeStamp(decoded.lastSampleTimeStamp);
            break;
          case 22:
            decoded.uplinkType = "first_sample_timestamp_response";
            decoded.voltageMv = parseInt(hexBytes.slice(4, 6).join(""), 16);
            decoded.firstTimeStamp = parseInt(
              hexBytes.slice(0, 4).join(""),
              16
            );
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            setVoltage(decoded.voltageMv);
            decoded.moment = moment
              .utc(decoded.firstTimeStamp * 1000)
              .format("MMMM, D YYYY, hh:mm:ss A");
            setMomentTime(decoded.moment);
            setFirstTimeStamp(decoded.firstTimeStamp);
            break;
          case 25:
            decoded.uplinkType = "configuration_acknowledgment";
            decoded.confirmationAckId = parseInt(hexBytes.slice(0, 1), 16);
            decoded.confirmedRecordPeriod = parseInt(
              hexBytes.slice(1, 3).join(""),
              16
            );
            decoded.confirmedReportPeriod = parseInt(
              hexBytes.slice(3, 5).join(""),
              16
            );
            decoded.confirmedRecordReportUnit = parseInt(hexBytes[5], 16);
            setAckId(decoded.confirmationAckId);
            setRecordPeriod(decoded.confirmedRecordPeriod);
            setReportPeriod(decoded.confirmedReportPeriod);
            if (decoded.confirmedRecordReportUnit == 0) {
              setRecordUnit("minutes");
            }
            if (decoded.confirmedRecordReportUnit == 1) {
              setRecordUnit("seconds");
            }

            break;
          case 26:
            decoded.uplinkType = "report_frame";
            decoded.temperatureUnit = "celsius";
            const combinedHexValues = combineBytePairs(hexBytes).map(function (
              e
            ) {
              return e.join("");
            });
            decoded.frameId = parseInt(combinedHexValues.splice(0, 1)[0], 16);
            setFid(decoded.frameId);
            decoded.temperatures = combinedHexValues.map((val, index, arr) => {
              const parsedValue = parseInt(val, 16);
              const temperature = (parsedValue - 5000) / 100;
              return {
                temperatureCelsius: temperature,
              };
            });

            let array = [];
            let array2 = [];
            for (let i = 0; i < combinedHexValues.length; i++) {
              if (
                combinedHexValues.includes("FFFC") &&
                combinedHexValues.includes("FFFF")
              ) {
                setShowError(true);
                const error = `One or more collected temperature samples is out of operating range (Error code FFFF)\nUnable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FFFC)`;

                setErrorMessage(error);
              } else {
                setShowError(false);
                setErrorMessage("");
              }
              array2.push(combinedHexValues[i]);
            }
            for (let i = 0; i < decoded.temperatures.length; i++) {
              if (decoded.temperatures[i].temperatureCelsius == 605.35) {
                array.push("FFFF");
              } else if (decoded.temperatures[i].temperatureCelsius == 605.32) {
                array.push("FFFC");
              } else {
                array.push(decoded.temperatures[i].temperatureCelsius);
              }
            }
            setFinalTemps(array);
            break;

          case 27:
            decoded.uplinkType = "recover_response";
            decoded.temperatureUnit = "celsius";
            const combinedHexValues27 = combineBytePairs(hexBytes).map(
              function (e) {
                return e.join("");
              }
            );
            decoded.frameId = parseInt(combinedHexValues27.splice(0, 1)[0], 16);
            setFid(decoded.frameId);
            decoded.temperatures = combinedHexValues27.map(
              (val, index, arr) => {
                const parsedValue = parseInt(val, 16);
                const temperature = (parsedValue - 5000) / 100;
                return {
                  temperatureCelsius: temperature,
                };
              }
            );

            let array27 = [];
            let array277 = [];
            for (let i = 0; i < combinedHexValues27.length; i++) {
              if (
                combinedHexValues27.includes("FFFC") &&
                combinedHexValues27.includes("FFFF")
              ) {
                setShowError(true);
                const error = `One or more collected temperature samples is out of operating range (Error code FFFF)\nUnable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FFFC)`;

                setErrorMessage(error);
              } else if (combinedHexValues27.includes("FFFF")) {
                setShowError(true);
                setErrorMessage(
                  "One or more collected temperature samples is out of operating range (Error code FFFF)"
                );
                // return;
              } else if (combinedHexValues27.includes("FFFC")) {
                setShowError(true);
                setErrorMessage(
                  "Unable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FFFC)"
                );
                // return;
              } else {
                setShowError(false);
                setErrorMessage("");
              }
              array277.push(combinedHexValues27[i]);
            }
            for (let i = 0; i < decoded.temperatures.length; i++) {
              if (decoded.temperatures[i].temperatureCelsius == 605.35) {
                array27.push("FFFF");
              } else if (decoded.temperatures[i].temperatureCelsius == 605.32) {
                array27.push("FFFC");
              } else {
                array27.push(decoded.temperatures[i].temperatureCelsius);
              }
            }
            setFinalTemps(array27);
            break;

          case 28:
            decoded.uplinkType = "configuration_request";
            decoded.voltageMv = parseInt(hexBytes.slice(1, 3).join(""), 16);
            decoded.temp_ext = parseInt(hexBytes.slice(0, 1).join(""), 16) - 50;
            setTempExt(decoded.temp_ext);
            setVoltage(decoded.voltageMv);
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            if (hexBytes.slice(0, 1) == "FC") {
              setErrorMessage(
                "Unable to communicate with temperature sensor. NanoTag should be considered unreliable (Error Code FC)"
              );
              setShowError(true);
            } else if (hexBytes.slice(0, 1) == "FF") {
              setErrorMessage(
                "Collected temperature sample is out of operating range (Error Code FF)"
              );
              setShowError(true);
            } else {
              setErrorMessage("");
              setShowError(false);
            }
            break;
          case 31:
            decoded.uplinkType = "low_voltage_warning";
            decoded.voltageMv = parseInt(hexBytes.join(""), 16);
            setVoltage(decoded.voltageMv);
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            break;
          case 32:
            decoded.uplinkType = "device_shutdown";
            decoded.voltageMv = parseInt(hexBytes.join(""), 16);
            setVoltage(decoded.voltageMv);
            decoded.batteryStatus = getSimpleBatteryStatus(decoded.voltageMv);
            setBatteryStatus(decoded.batteryStatus);
            break;
        }

      case "downlink":
        switch (port) {
          case 20:
            if (hexBytes.slice(0, 1) == "01") {
              decoded.adr = "Enable";
              setAdrValue(decoded.adr);
            }
            if (hexBytes.slice(0, 1) == "00") {
              decoded.adr = "Disable";
              setAdrValue(decoded.adr);
            }
            if (hexBytes.slice(1, 3) == "01") {
              decoded.confirm = "Enable";
              setConfirmedValue(decoded.confirm);
            }
            if (hexBytes.slice(1, 3) == "00") {
              decoded.confirm = "Disable";
              setConfirmedValue(decoded.confirm);
            }
            break;

          case 28:
            decoded.recordPeriod = parseInt(hexBytes.slice(0, 2).join(""), 16);
            decoded.reportPeriod = parseInt(hexBytes.slice(2, 4).join(""), 16);
            setRecordPeriod(decoded.recordPeriod);
            setReportPeriod(decoded.reportPeriod);
            break;

          case 23:
            if (hexBytes.slice(0, 1) == "00") {
              decoded.networkSwitch = "0";
              setNetworkSwitch(decoded.networkSwitch);
            }
            if (hexBytes.slice(0, 1) == "01") {
              decoded.networkSwitch = "1";
              setNetworkSwitch(decoded.networkSwitch);
            }
            decoded.joinEUI = hexBytes.slice(1, 9);
            setJoinEUI(decoded.joinEUI);
            decoded.netID = hexBytes.slice(10, 11);
            setNetID(decoded.netID);
            decoded.US915SubBand = getUS915subband(hexBytes.slice(9, 10));
            setUS915SubBand(decoded.US915SubBand);

            break;
          case 29:
            decoded.recordPeriod = parseInt(hexBytes.slice(0, 2).join(""), 16);
            decoded.reportPeriod = parseInt(hexBytes.slice(2, 4).join(""), 16);
            setRecordPeriod(decoded.recordPeriod);
            setReportPeriod(decoded.reportPeriod);
            break;
          case 26:
            decoded.packetsRequest = parseInt(hexBytes.slice(0, 1), 16);
            // decoded.fid1 = parseInt(hexBytes.slice(1, 3).join(''), 16);
            // decoded.fid2 = parseInt(hexBytes.slice(3, 5).join(''), 16);
            // decoded.fid3 = parseInt(hexBytes.slice(5, 7).join(''), 16);

            // setfid1(decoded.fid1)
            // setfid2(decoded.fid2)
            // setfid3(decoded.fid3)
            let hexBytesNew = hexBytes;
            let arrayRemove = hexBytesNew.shift();
            const combinedHexValues26 = combineBytePairs(hexBytesNew).map(
              function (e) {
                return e.join("");
              }
            );

            decoded.allFid = combinedHexValues26.map((val, index, arr) => {
              const parsedValueS = parseInt(val, 16);
              console.log("parsedValueS", parsedValueS);
              return {
                val26: parsedValueS,
              };
            });

            let newArray = [];
            for (let i = 0; i < decoded.allFid.length; i++) {
              newArray.push(decoded.allFid[i].val26);
            }
            setAllFid(newArray);

            break;

          case 27:
            decoded.lowerFid = parseInt(hexBytes.slice(0, 2).join(""), 16);
            decoded.upperFid = parseInt(hexBytes.slice(2, 4).join(""), 16);
            setLowerFid(decoded.lowerFid);
            setUpperFid(decoded.upperFid);
            break;
        }
    }

    // console.log("decoded", decoded.temperatures)
    // console.log("temp ext", decoded.temp_ext)
    // console.log("temp_int", decoded.temp_int)
    // console.log("decoded.voltageMvt", decoded.voltageMv)
    // setUpLinkType(decoded.uplinkType)
    return decoded;
  }

  function getSimpleBatteryStatus(voltageMv) {
    if (voltageMv > 2850) {
      return "Excellent";
    } else if (voltageMv > 2750) {
      return "Good";
    } else if (voltageMv > 2650) {
      return "Low";
    } else if (voltageMv <= 2650) {
      return "Critical";
    } else {
      return "Invalid voltage value";
    }
  }

  function getUS915subband(hex) {
    if (hex == "00") {
      return "Continue to use the existing channel mask configuration";
    } else if (hex == "01") {
      return "Channels 0-7 + 64 (sub-band 1)";
    } else if (hex == "02") {
      return "Channels 8-15 + 65 (sub-band 2)";
    } else if (hex == "03") {
      return "Channels 16-23 + 66 (sub-band 3)";
    } else if (hex == "04") {
      return "Channels 23-30 + 67 (sub-band 4)";
    }
  }

  function combineBytePairs(array) {
    let offset = 0;
    const bytePairs = [];
    while (offset < array.length) {
      bytePairs.push(array.slice(offset, (offset += 2)));
    }
    return bytePairs;
  }

  // const bytes = "0959"

  const [payLoad, setPayLoad] = useState("");

  const [port, setPort] = useState("");

  const [upLinkType, setUpLinkType] = useState("");

  const allPorts = [
    // {
    //   value: 1,
    //   label: "Port 1: Boot Message",
    // },

    {
      value: 13,
      label: "Port 13: Health Message",
    },
    {
      value: 21,
      label: "Port 21: Device Status Response",
    },
    // {
    //   value: 22,
    //   label: "Port 22: First Sample Timestamp Response",
    // },

    {
      value: 25,
      label: "Port 25: Configuration Acknowledgement",
    },

    {
      value: 26,
      label: "Port 26: Report Frame",
    },

    // {
    //   value: 27,
    //   label: "Port 27: Recover Response",
    // },

    {
      value: 28,
      label: "Port 28: Configuration Request",
    },

    // {
    //   value: 31,
    //   label: "Port 31: Low Voltage Warning",
    // },

    // {
    //   value: 32,
    //   label: "Port 32: Device Shutdown Acknowledgement",
    // },
  ];

  const allPortsDownlink = [
    {
      value: 28,
      label: "Port 28: Configuration Response (Minutes)",
    },

    {
      value: 29,
      label: "Port 29: Configuration Response (Seconds)",
    },

    {
      value: 26,
      label: "Port 26: Recover Request (FID)",
    },

    {
      value: 27,
      label: "Port 27: Recover Request (Bulk)",
    },

    {
      value: 20,
      label: "Port 20: LoRaWAN Parameter Setup",
    },
    {
      value: 23,
      label: "Port 23: Rejoin Request",
    },
  ];

  const packetTypes = [
    {
      value: "uplink",
      label: "Uplink Packets",
    },

    {
      value: "downlink",
      label: "Downlink Packets",
    },
  ];

  useEffect(() => {
    // Decoder(bytes, 31)
  }, []);

  const [payloadSelectedType, setPayloadSelectedType] = useState("uplink");

  const [base64, setBase64] = useState("");
  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [modalOpen, setModalOpen] = useState(false);
  const copyToClipboard = (val) => {
    window.navigator.clipboard.writeText(val);
    // toast("Copied to clipboard");
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="container" style={{marginTop:150}}>
      <div>
        <h2 style={{ textAlign: "center" }}>
          NanoTag Uplink/Downlink Payload Decoder
        </h2>
        <p style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
          This tool can be used to decode NanoTag uplink and downlink payloads
          for demonstration and debugging purposes.
        </p>
        <Container style={{}}>
          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              <TextField
                autoComplete="off"
                required
                label="Payload (Hex)"
                id="outlined-start-adornment"
                sx={{
                  m: 1,
                  width: "50ch",
                  "& label": {
                    // fontFamily: 'OpenSans',
                  },
                }}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9a-fA-F]/g, "");
                  setPayLoad(newValue);

                  if (newValue == "") {
                    setShowError(false);
                    setErrorMessage("");
                  }

                  if (newValue != "") {
                    Decoder(newValue, port, payloadSelectedType, base64);
                  }
                  hexToBase64(newValue);
                }}
                value={payLoad}
              />

              <button
                className={classes.buttonStyle}
                onClick={() => {
                  copyToClipboard(payLoad.toUpperCase());
                }}
              >
                <ContentCopyIcon className={classes.copyStyle} />
              </button>
            </div>
          </div>

          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              <TextField
                autoComplete="off"
                required
                label="Payload (Base64)"
                id="outlined-start-adornment"
                sx={{
                  m: 1,
                  width: "50ch",
                  "& label": {
                    // fontFamily: 'OpenSans',
                  },
                }}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[ ]/g, "");
                  setBase64(newValue);

                  base64ToHex(newValue);
                  if (newValue == "") {
                    setShowError(false);
                    setErrorMessage("");
                  }
                  if (newValue != "") {
                    Decoder(
                      base64ToHex(newValue),
                      port,
                      payloadSelectedType,
                      base64
                    );
                  }

                  // Decoder(e.target.value, port, payloadSelectedType)
                  // if (e.target.value == "") {
                  //     setPayLoadError(true)
                  // }
                  // else {
                  //     setPayLoadError(false)
                  //     Decoder(e.target.value, port, payloadSelectedType)
                  // }
                }}
                value={base64}
              />

              <button
                className={classes.buttonStyle}
                onClick={() => copyToClipboard(base64)}
              >
                <ContentCopyIcon className={classes.copyStyle} />
              </button>
            </div>
          </div>

          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              {payloadSelectedType == "uplink" && (
                <TextField
                  autoComplete="off"
                  id="outlined-select-currency"
                  select
                  label="Port"
                  value={port}
                  onChange={(e) => {
                    setPort(e.target.value);
                    if (payLoad == "" || payLoad == null) {
                      setPort(e.target.value);
                    } else {
                      Decoder(
                        payLoad,
                        e.target.value,
                        payloadSelectedType,
                        base64
                      );
                    }
                  }}
                  sx={{
                    m: 1,
                    width: "50ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                >
                  {allPorts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {payloadSelectedType == "downlink" && (
                <TextField
                  autoComplete="off"
                  id="outlined-select-currency"
                  select
                  label="Port"
                  value={port}
                  onChange={(e) => {
                    setPort(e.target.value);
                    if (payLoad == "" || payLoad == null) {
                      setPort(e.target.value);
                    } else {
                      Decoder(
                        payLoad,
                        e.target.value,
                        payloadSelectedType,
                        base64
                      );
                    }
                  }}
                  sx={{
                    m: 1,
                    width: "50ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                >
                  {allPortsDownlink.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </div>
          </div>

          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e, val) => {
                  if (payLoad !== "") {
                    Decoder(payLoad, port, val, base64);
                  }
                  setPayloadSelectedType(val);
                }}
                value={payloadSelectedType}
              >
                {packetTypes.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          {payloadSelectedType == "uplink" &&
            payLoad !== "" &&
            payLoad != null && (
              <div className={classes.textFieldView}>
                {(port == 1 || port == 13) &&
                  (payLoad.length == 8 ? (
                    <>
                      {showError ? (
                        <h5 className="text-center">
                          Temp Ext: {payLoad.slice(0, 2) == "FF" ? "FF" : "FC"}
                        </h5>
                      ) : (
                        <h5 className="text-center">
                          Temp Ext: {tempExt} &deg;C,{" "}
                          {(1.8 * tempExt + 32).toFixed(2)} &deg;F
                        </h5>
                      )}

                      <h5 className="text-center">
                        Temp Int: {tempInt} &deg;C,{" "}
                        {(1.8 * tempInt + 32).toFixed(2)} &deg;F
                      </h5>
                      <h5 className="text-center">Voltage (mV): {voltage}</h5>
                      <h5 className="text-center">
                        Battery Status: {batteryStatus}
                      </h5>
                      {showError && (
                        <h5 className="text-center" style={{ color: "red" }}>
                          {errorMessage}
                        </h5>
                      )}
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Temp Ext: N/A</h5>
                      <h5 className="text-center">Temp Int: N/A</h5>
                      <h5 className="text-center">Voltage (mV): N/A</h5>
                      <h5 className="text-center">Battery Status: N/A</h5>
                    </>
                  ))}

                {port == 25 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 12 ? (
                    <>
                      <h5 className="text-center">ID: {ackId}</h5>
                      <h5 className="text-center">
                        Record period: {recordPeriod} {recordUnit}
                      </h5>
                      <h5 className="text-center">
                        Report period: {reportPeriod} {recordUnit}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">ID: N/A</h5>

                      <h5 className="text-center">Record period: N/A</h5>
                      <h5 className="text-center">Report period: N/A</h5>
                    </>
                  ))}
                {port == 28 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 6 ? (
                    <>
                      {showError ? (
                        <h5 className="text-center" style={{ color: "red" }}>
                          Temperature:{" "}
                          {payLoad.slice(0, 2) == "FF" ? "FF" : "FC"}
                        </h5>
                      ) : (
                        <h5 className="text-center">
                          Temperature: {tempExt} &deg;C,{" "}
                          {(1.8 * tempExt + 32).toFixed(2)} &deg;F
                        </h5>
                      )}

                      <h5 className="text-center">Voltage (mV): {voltage}</h5>
                      <h5 className="text-center">
                        Battery Status: {batteryStatus}
                      </h5>
                      {showError && (
                        <h5 className="text-center" style={{ color: "red" }}>
                          {errorMessage}
                        </h5>
                      )}
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Temperature: N/A</h5>
                      <h5 className="text-center">Voltage (mV): N/A</h5>
                      <h5 className="text-center">Battery Status: N/A</h5>
                    </>
                  ))}

                {(port == 26 || port == 27) &&
                  payLoad !== "" &&
                  payLoad != null && (
                    <>
                      <h5 className="text-center">
                        Frame ID: {payLoad.length >= 8 ? fid : "N/A"}{" "}
                      </h5>
                      {showError && (
                        <h5
                          className="text-center"
                          style={{
                            color: "red",
                            flex: 1,
                            whiteSpace: "pre-line",
                          }}
                        >
                          {errorMessage}
                        </h5>
                      )}

                      <h5 className="text-center">Temperatures:</h5>
                      {payLoad.length < 8 ? (
                        <h5 className="text-center">N/A</h5>
                      ) : (
                        <div
                          className="col-lg-3 container text-center"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <table>
                            <thead>
                              <tr>
                                <th className="text-center">
                                  <h5>&deg;C</h5>
                                </th>
                                <th className="text-center">
                                  <h5>&deg;F</h5>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="text-center">
                                  {finalTemps.map((temp, i) => (
                                    <h5
                                      key={i}
                                      className="text-center"
                                      style={{
                                        color:
                                          temp == "FFFF" || temp == "FFFC"
                                            ? "red"
                                            : "black",
                                      }}
                                    >
                                      {temp}
                                    </h5>
                                  ))}
                                </th>
                                <th className="text-center">
                                  {finalTemps.map((temp, i) =>
                                    temp == "FFFF" ? (
                                      <h5
                                        key={i}
                                        className="text-center"
                                        style={{ color: "red" }}
                                      >
                                        FFFF
                                      </h5>
                                    ) : temp == "FFFC" ? (
                                      <h5
                                        key={i}
                                        className="text-center"
                                        style={{ color: "red" }}
                                      >
                                        FFFC
                                      </h5>
                                    ) : (
                                      <h5 key={i} className="text-center">
                                        {(1.8 * temp + 32).toFixed(2)}
                                      </h5>
                                    )
                                  )}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}

                {(port == 31 || port == 32) &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 4 ? (
                    <>
                      <h5 className="text-center">Voltage (mV): {voltage}</h5>
                      <h5 className="text-center">
                        Battery Status: {batteryStatus}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Voltage (mV): N/A</h5>
                      <h5 className="text-center">Battery Status: N/A</h5>
                    </>
                  ))}

                {port == 21 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 20 ? (
                    <>
                      <h5 className="text-center">
                        Last Sample Number: {lastSampleNumber}
                      </h5>
                      <h5 className="text-center">
                        Last Sample Timestamp (seconds): {lastSampleTimeStamp}
                      </h5>
                      <h5 className="text-center">GPS time: {momentTime}</h5>
                      {/* <h5 className="text-center">Translates to GPS Time: {firstTimeStamp}</h5>
                                    <h5 className="text-center">UTC Time: {firstTimeStamp}</h5> */}
                      <h5 className="text-center">Voltage (mV): {voltage}</h5>
                      <h5 className="text-center">
                        Battery Status: {batteryStatus}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Last Sample Number: N/A</h5>
                      <h5 className="text-center">
                        Last Sample Timestamp (seconds): N/A
                      </h5>
                      <h5 className="text-center">GPS time: N/A</h5>
                      {/* <h5 className="text-center">Translates to GPS Time: {firstTimeStamp}</h5>
                                                <h5 className="text-center">UTC Time: {firstTimeStamp}</h5> */}
                      <h5 className="text-center">Voltage (mV): N/A</h5>
                      <h5 className="text-center">Battery Status: N/A</h5>
                    </>
                  ))}

                {port == 22 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 12 ? (
                    <>
                      <h5 className="text-center">
                        First Time Stamp (seconds): {firstTimeStamp}
                      </h5>
                      {/* <h5 className="text-center">Translates to GPS Time: {firstTimeStamp}</h5>
                                    <h5 className="text-center">UTC Time: {firstTimeStamp}</h5> */}
                      <h5 className="text-center">Voltage (mV): {voltage}</h5>
                      <h5 className="text-center">
                        Battery Status: {batteryStatus}
                      </h5>
                      <h5 className="text-center">GPS time: {momentTime}</h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">
                        First Time Stamp (seconds): N/A
                      </h5>
                      <h5 className="text-center">Voltage (mV): N/A</h5>
                      <h5 className="text-center">Battery Status: N/A</h5>
                      <h5 className="text-center">GPS time: N/A</h5>
                    </>
                  ))}
              </div>
            )}

          {payloadSelectedType == "downlink" &&
            payLoad !== "" &&
            payLoad != null && (
              <div className={classes.textFieldView}>
                {port == 28 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 8 ? (
                    <>
                      <h5 className="text-center">
                        Record period: {recordPeriod} minutes
                      </h5>
                      <h5 className="text-center">
                        Report period: {reportPeriod} minutes
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Record period: N/A</h5>
                      <h5 className="text-center">Report period: N/A</h5>
                    </>
                  ))}

                {port == 20 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 4 ? (
                    <>
                      <h5 className="text-center">
                        ADR: {adrValue} Adaptive Data Rate
                      </h5>
                      <h5 className="text-center">
                        Confirmed: {confirmedValue} Confirmed Uplinks for Report
                        Frames
                      </h5>
                    </>
                  ) : (
                    <>
                      {" "}
                      <h5 className="text-center">ADR: N/A</h5>
                      <h5 className="text-center">Confirmed: N/A</h5>
                    </>
                  ))}

                {port == 29 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 8 ? (
                    <>
                      <h5 className="text-center">
                        Record period: {recordPeriod} seconds
                      </h5>
                      <h5 className="text-center">
                        Report period: {reportPeriod} seconds
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Record period: N/A</h5>
                      <h5 className="text-center">Report period: N/A</h5>
                    </>
                  ))}

                {port == 26 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length >= 6 && payLoad.length <= 18 ? (
                    <>
                      <h5 className="text-center">Frame ID(s) </h5>

                      {allFid.map((fid) => (
                        <h5 key={fid} className="text-center">
                          {fid}
                        </h5>
                      ))}
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">Frame ID(s) N/A</h5>
                    </>
                  ))}

                {port == 27 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 8 ? (
                    <>
                      <h5 className="text-center">
                        lower Frame ID: {lowerFid}
                      </h5>
                      <h5 className="text-center">
                        upper Frame ID: {upperFid}
                      </h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">lower Frame ID: N/A</h5>
                      <h5 className="text-center">upper Frame ID: N/A</h5>
                    </>
                  ))}

                {port == 23 &&
                  payLoad !== "" &&
                  payLoad != null &&
                  (payLoad.length == 22 ? (
                    <>
                      <h5 className="text-center">
                        NetworkSwitch: {networkSwitch}
                      </h5>
                      <h5 className="text-center">NewJoinEUI: {joinEUI}</h5>
                      <h5 className="text-center">
                        US915SubBand: {US915SubBand}
                      </h5>
                      <h5 className="text-center">NetID: {netID}</h5>
                    </>
                  ) : (
                    <>
                      <h5 className="text-center">NetworkSwitch: N/A</h5>
                      <h5 className="text-center">NewJoinEUI: N/A</h5>
                      <h5 className="text-center">US915SubBand: N/A</h5>
                      <h5 className="text-center">NetID: N/A</h5>
                    </>
                  ))}
              </div>
            )}
          {/* 
                    <h5 className="text-center">base64 is: {base64}</h5>
                    <h5 className="text-center">payload is: {payLoad}</h5>
 */}

          <Snackbar
            open={modalOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Copied to clipboard"
            action={action}
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
          />
        </Container>
      </div>
    </div>
  );
};
export default PayloadDecoder;
