import React from "react";
import inventory from "../assets/image/real-time/Warehouse-V2-small.jpg";
import freezer from "../assets/image/real-time/Walk-in-freezer-small.jpg";
import dcSmall from "../assets/image/real-time/DC-small.jpg";
import realTimeVideo from "../assets/video/NanoThingsFinalVideo.mp4";
import shape2 from "../assets/image/l1/shape2.svg";
import priceShape from "../assets/image/l1/price-black-shape.svg";
import battery from "../assets/image/real-time/2.svg";
import locIcon from "../assets/image/real-time/1.svg";
import batteryLife from "../assets/image/icon-3.svg";
import checkMark from "../assets/image/check.svg";
import phone from "../assets/image/real-time/3.svg";
import store from "../assets/image/icon-6.svg";
import { makeStyles } from "@mui/styles";

const RealTime = () => {
  const classes = useStyles();
  return (
    <>
      <div className="sub-page-title">
        <canvas id="map"></canvas>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h1>Real Time™</h1>
                <p>
                  A protocol for real time temperature and location monitoring
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-area-l8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center pb-lg-5">
                <h2>NanoThings Real Time for In-Transit Applications</h2>
                <p>
                  The NanoThings Real Time protocol is specifically designed to
                  leverage the Helium network (or any public LoRaWAN network) to
                  power track and trace applications. Temperature monitoring and
                  location tracking applications are natively supported by the
                  protocol.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
            <div className="container mb-5">
              <div className="row justify-content-center mb-2">
                <video muted loop autoPlay>
                  <source src={realTimeVideo} type="video/mp4" />
                </video>
              </div>
              <p style={{ fontSize: 12 }}>
                Tracking visualization provided by Senzary
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 mb-5"></div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center pb-lg-5">
                <h2>NanoThings Real Time for Static Applications</h2>
                <p>
                  NanoThings Real Time for static monitoring is an
                  infrastructure-less solution for tracking and temperature
                  monitoring assets that remain stationary.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center clients-l8-items">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="client-l8-card">
                <div className="image">
                  <img
                    src={inventory}
                    alt="image"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="content">
                  <h4>Inventory Monitoring</h4>
                  <p>
                    NanoThings Real Time is designed to support high volume
                    applications like inventory monitoring. It automatically
                    performs validation checks against all uplinks to check for
                    missed data packets. If a packet was sent from the device
                    but not received by the cloud, it will automatically
                    downlink to the device and request that specific packet
                    again until it has been received.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="client-l8-card">
                <div className="image">
                  <img
                    src={freezer}
                    alt="image"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="content">
                  <h4>Static Temperature Monitoring</h4>
                  <p>
                    NanoThings Real Time for static temperature monitoring is a
                    simple yet highly effective solution for wireless
                    temperature monitoring of virtually any asset.
                  </p>
                  <p>
                    Predictive maintenance made simple. Place a NanoTag on the
                    asset and remotely monitor the temperature. No more manual
                    equipment checks. This solution is great for warm and cold
                    monitoring.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="client-l8-card">
                <div className="image">
                  <img
                    src={dcSmall}
                    alt="image"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="content">
                  <h4>Facility Temperature Monitoring</h4>
                  <p>
                    NanoThings Real Time provides an ultra scalable solution to
                    wireless facility temperature monitoring. It's as simple as
                    sticking a NanoTag anywhere in the facility for instant real
                    time temperature mapping.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#fff" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
                <h2>Features</h2>
              </div>
            </div>
          </div>
          <div className="row feature-l-11-items justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img src={store} alt="" className={classes.imageStyle} />
                </div>
                <div className="content-body">
                  <h5>Store and Forward</h5>
                  <p>
                    Automatic server side acknowledgments and requests for
                    missing data packets.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img src={phone} alt="" className={classes.imageStyle} />
                </div>
                <div className="content-body">
                  <h5>Custom Sampling and Reporting Intervals</h5>
                  <p>
                    Remotely change the sampling and/or reporting period by
                    sending a downlink.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img src={checkMark} alt="" className={classes.imageStyle} />
                </div>
                <div className="content-body">
                  <h5>Health Messages</h5>
                  <p>
                    Pre-activation daily health messages provide device health
                    prior to use.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img
                    src={batteryLife}
                    alt=""
                    className={classes.imageStyle}
                    style={{ alignSelf: "center" }}
                  />
                </div>
                <div className="content-body">
                  <h5>Battery Life Monitoring</h5>
                  <p>
                    Battery voltage sent once every 12 hours or upon request.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img src={locIcon} alt="" className={classes.imageStyle} />
                </div>
                <div className="content-body">
                  <h5>Location Tracking</h5>
                  <p>
                    Leverage any server side triangulation logic to track
                    NanoTags down to block level accuracy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className={classes.icon}>
                  <img
                    src={battery}
                    alt=""
                    style={{ alignSelf: "center" }}
                    className={classes.imageStyle}
                  />
                </div>
                <div className="content-body">
                  <h5>Battery Drain Mode</h5>
                  <p>
                    Devices can be remotely sent into high power mode to quickly
                    drain remaining battery for safe disposal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={priceShape} alt="" className="w-100" />
        </div>
      </div>
      {/* <squarespace:script src="three.js" />
      <squarespace:script src="tween.js" />
      <squarespace:script src="globe-map.js" /> */}
    </>
  );
};

export default RealTime;

const useStyles = makeStyles({
  icon: {
    padding: 5,
    objectFit: "contain",
    width: 100,
    height: "50%",
    // maxHeight: 80,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
    justifyContent: "center",
    alignContent: "center",
    margin: 15,
    justifyItems: "center",
  },
  imageStyle: {
    width: 80,
    height: 80,
  },
});
