import React, { useEffect } from "react";
import caseStudy1 from "../../assets/image/use-cases/case-study-asset-1.png";
import caseStudy2 from "../../assets/image/use-cases/case-study-asset-2.png";
import nanoTag from "../../assets/image/use-cases/NanotagImage.png";
import styles from "./CaseStudiesStyle";
const AssetTracking = () => {
  return (
    <>
      <div className="sub-page-title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h2
                  style={styles.title}
                >
                  Case Study: Asset Tracking
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-4">
        <p className="predictive-tag-text">Problem</p>
      </div>
      <div>
        <div className="container pt-lg-5 mb-5">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                A Fortune 500 company was experiencing a high amount of theft
                from its distribution centers. They had a hunch that the theft
                was coming from workers at the distribution center, but they had
                no way of confirming their hunch without catching the thief red
                handed.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="predictive-tag col-xl-4 col-lg-4 col-md-4">
        <p className="predictive-tag-text">Objective & Approach</p>
      </div>
      <div className="mt-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-10 col-lg-10 col-md-10">
              <div className="content section__heading">
                <p style={styles.text}>
                  They needed a tracker that could hide within a package and go
                  completely unnoticed by anyone other than a select group of
                  corporate level employees responsible for the investigation.
                  They contacted NanoThings after hearing about the NanoTag, the
                  smallest, most discreet, and only disposable tracker in the
                  industry.
                </p>
                <p style={styles.text} className="mt-3">
                  Unlike bulky, high cost GPS-based trackers, the NanoTag
                  determines location by triangulating around public LoRa
                  gateways. While not as accurate as GPS, removing expensive,
                  high battery drain GPS components allows the NanoTag to be
                  smaller, cheaper, and have an exceptionally longer battery
                  life than any GPS tracker. The customer determined that
                  GPS-level accuracy was not a requirement, and that block-level
                  accuracy from triangulation would more than suffice.
                </p>
                <p style={styles.text} className="mt-3">
                  NanoTags were discreetly placed inside high value items at the
                  distribution center prior to their ship dates. The NanoTags
                  were provisioned to transmit every three minutes. If the
                  NanoTag showed movement outside of the distribution center
                  prior to the ship date, the customer would have a timestamp of
                  when that movement occurred, so they could easily determine
                  who was on shift during that time. Furthermore, they would be
                  able to track if the items were ending up at the expected end
                  customer destination, or somewhere unexpected (like employee
                  addresses).{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="content-img">
                <img src={nanoTag} style={{ width: 275, height: 300 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-5">
        <p className="predictive-tag-text">Results</p>
      </div>
      <div className="mt-1">
        <div className="container pt-lg-5 ">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                The company was shocked to see immediate discrepancies between
                their internal system data and the NanoTag data. While their
                internal systems showed all items at the distribution center,
                the NanoTag data proved otherwise.
              </p>

              <p style={styles.text} className="mt-3">
                Not only was the NanoTag able to prove that items were leaving
                the distribution center on unauthorized shipments, the customer
                was able to reroute some of the trucks back to the distribution
                center in real time.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-10">
            <div className="text-center">
              <img src={caseStudy1} style={{ width: 600, height: 500 }} />
              <p className="mt-2" style={{ fontStyle: "italic" }}>
                (Above image shows the route a truck took after departing the
                distribution center with unauthorized merchandise. This truck
                was rerouted in real time back to the distribution center)
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-10">
            <div className="text-center">
              <img src={caseStudy2} style={{ width: 600, height: 500 }} />
              <p className="mt-2" style={{ fontStyle: "italic" }}>
                (A truck departing the distribution center and dropping off
                merchandise at an unauthorized location)
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3   mb-5">
        <div className="container">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                In other cases, the customer was able to identify that the
                merchandise was indeed shipped to the wrong location, with
                further investigation ensuing.
              </p>

              <p style={styles.text} className="mt-3">
                The customer was extremely pleased with the NanoTag’s
                performance, and is now developing an initiative to integrate
                the NanoTag into high value items.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssetTracking;
