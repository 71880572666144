import React from "react";
import logo from "../assets/image/logo/logo.svg";
const Footer = () => {
  return (
    <footer className="footer-area-l-12 position-relative">
      <div className="container">
        <div className="footer-l-12-top-border"></div>
        <div className="row justify-content-center footer-l1-area-items">
          <div className="col-xl-6 col-lg-4 col-md-12 col-xs-12 col-12 order-2 order-lg-0">
            <div className="footer-content-l9 text-sm-center text-lg-start">
              <a href="/" className="logo-footer">
                <img src={logo} alt="image" />
              </a>
              <p>1460 Broadway, Suite 14034 New York, NY 10036</p>
              <a
                style={{ textDecoration: "none" }}
                href="mailto:info@nanothingsinc.com"
              >
                info@nanothingsinc.com
              </a>

              <ul className="list-unstyled footer-social-icon-l2">
                <li>
                  <a href="https://www.facebook.com/NanoThingsInc/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/nanothingsinc">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/nanothingsinc">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-xs-4 col-6 order-2 order-lg-1">
            <h3>Company</h3>
            <ul className="list-unstyled">
              <li>
                <a href="/about">Who We Are</a>
              </li>
              {/* <li>
                <a href="/team">Leadership Team</a>
              </li> */}
              <li>
                <a href="/support">Support Plans</a>
              </li>
              <li>
                <a href="/pricing">Protocol Plans</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              {/* <li>
                <a href="/faqs">FAQs</a>
              </li> */}
            </ul>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-xs-4 col-6 order-3 order-lg-2">
            <h3>Tools & Resources</h3>
            <ul className="list-unstyled">
              {/* <li>
                <a href="/nanotag">NanoTag</a>
              </li> */}
              <li>
                <a href="/nanotag-battery-life-estimator">
                  NanoTag Battery Life Estimator
                </a>
              </li>
              <li>
                <a href="/nanotag-payload-decoder">NanoTag Payload Decoder</a>
              </li>
              <li>
                <a href="/configuration-response-downlink-encoder">
                  Configuration Response Downlink Encoder
                </a>
              </li>
              <li>
                <a target="_blank" href="https://discord.gg/NwWsZWFr">
                  Discord
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-xs-4 col-12 order-4 order-lg-3">
            <h3>NanoTag Protocols</h3>
            <ul className="list-unstyled ">
              <li>
                <a href="/real-time">NanoThings Real Time</a>
              </li>
              <li>
                <a href="/closed-loop">NanoThings Closed Loop</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-l-12-top-border __1"></div>
        <div className="footer-copyright-wrapper">
          <div className="footer-copyright-wrapper-col">
            <div className="paragraph small footer-chainlinkreg">
              © 2021 NanoThings, Inc. NanoThings is a registered trademark of
              NanoThings, Inc.
            </div>
          </div>
          <div className="footer-copyright-wrapper-col footer-privacyandterms">
            <a
              style={{ textDecoration: "none" }}
              href="/privacypolicy"
              className="paragraph small footer-copyright"
            >
              Privacy Policy
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="/terms"
              className="paragraph small footer-copyright"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
