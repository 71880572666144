import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "../src/components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Buy from "./pages/Buy";
import Pricing from "./pages/Pricing";
import Support from "./pages/Support";
import RealTime from "./pages/RealTime";
import ClosedLoop from "./pages/ClosedLoop";
import Terms from "./pages/Terms";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import FAQs from "./pages/FAQs";
import ContactUs from "./pages/ContactUs";
import AssetTracking from "./pages/CaseStudies/AssetTracking";
import ColdChain from "./pages/CaseStudies/ColdChain";
import Predective from "./pages/CaseStudies/Predective";
import StaticTemp from "./pages/CaseStudies/StaticTemp";
import EcoSystem from "./pages/EcoSystem";
import Calculator from "./pages/Calculation/BatteryLife/BatteryLifeCalculator";
import ConfigurationEncoder from "./pages/Calculation/ConfigurationalResponse/ConfigurationalResponse";
import PayloadDecoder from "./pages/Calculation/PayloadDecoder/PayloadDecoder";
import NanoTag from "./pages/NanoTag";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/buy" element={<Buy />} /> */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/real-time" element={<RealTime />} />
          <Route path="/closed-loop" element={<ClosedLoop />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/team" element={<Team />} /> */}
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/case-study-asset-tracking" element={<AssetTracking />} />
          <Route path="/case-study-cold-chain" element={<ColdChain />} />
          <Route path="/case-study-predictive" element={<Predective />} />
          <Route path="/use-case-static-temperature" element={<StaticTemp />} />
          <Route path="/ecosystem" element={<EcoSystem />} />
          <Route path="/configuration-response-downlink-encoder" element={<ConfigurationEncoder />} />
          <Route path="/nanotag-battery-life-estimator" element={<Calculator />} />
          <Route path="/nanotag-payload-decoder" element={<PayloadDecoder />} />
          <Route path="/nanotag" element={<NanoTag />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
