import React, { useState } from "react";

function AccordionItem({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };
  console.log("is active", isActive);

  return (
    <li className={isActive ? "active" : ""}>
      <a
        className={`accordion ${isActive ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        {title}
      </a>
      {isActive ? (
        <>
          <div style={{ marginTop: 20 }} />
          <h5
            className={`accordion ${isActive ? "active" : ""}`}
            style={{ fontSize: 15 }}
          >
            {content}
          </h5>
        </>
      ) : null}
    </li>
  );
}

function FAQMainArea() {
  return (
    <div className="faq-main-area" style={{ marginTop: 150 }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="faq-main-tab-area sticky">
              <ul className="list-unstyled">
                <li className="active">
                  <a href="#general">General FAQs</a>
                </li>
                <li>
                  <a href="#lorawan">LoRaWAN FAQs</a>
                </li>
                <li>
                  <a href="#nanotag">NanoTag FAQs</a>
                </li>
                <li>
                  <a href="#pricing">Pricing FAQs</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-8 col-md-10 faq-single-all-items">
            <div className="faq-main-area-single" id="general">
              <h2>General FAQs</h2>
              <ul className="accordion p-0">
                <AccordionItem
                  title="What is NanoThings?"
                  content="NanoThings is a supply/cold chain monitoring solution that utilizes proprietary sensor technology, called NanoTags, to enable what we call source-to-store traceability.."
                />
              </ul>
            </div>
            <div className="faq-main-area-single" id="lorawan">
              <h2>LoRaWAN FAQs</h2>
              <ul className="accordion p-0">
                <AccordionItem
                  title="What is LoRaWAN?"
                  content="LoRaWAN stands for Long Range Wide Area Network..."
                />
              </ul>
            </div>
            <div className="faq-main-area-single" id="nanotag">
              <h2>NanoTag FAQs</h2>
              <ul className="accordion p-0">
                <AccordionItem
                  title="Do NanoTags use cellular to communicate?"
                  content="No. NanoTags utilize a wireless protocol called LoRaWAN..."
                />
              </ul>
            </div>

            <div className="faq-main-area-single" id="pricing">
              <h2>Pricing FAQs</h2>
              <ul className="accordion p-0">
                <AccordionItem
                  title="How much do NanoTags cost?"
                  content="If you purchase NanoTags directly from NanoThings..."
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FAQs() {
  return (
    <div>
      <FAQMainArea />
    </div>
  );
}

export default FAQs;
