import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router";
import useStyles from "../BatteryLife/CalculatorStyle";
import TextField from "@mui/material/TextField";
// import { TextField } from '@mui/material';
import Container from "@mui/material/Container";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";
import QuestionMark from "@mui/icons-material/Help";

const ConfigurationEncoder = () => {
  const [state, setState] = React.useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [recordPeriod, setRecordPeriod] = useState("");
  const [recordPeriodError, setRecordPeriodError] = useState(false);

  const [reportPeriod, setReportPeriod] = useState("");
  const [reportPeriodError, setReportPeriodError] = useState(false);

  const [multipleError, setMultipleError] = useState(false);

  const [hexValue, setHexValue] = useState("");
  const [base64, setBase64] = useState("");

  const classes = useStyles();

  function convert(integer) {
    var str = Number(integer).toString(16);
    while (str.length < 4) {
      str = "0" + str;
    }
    return str;
  }

  function toHexFunction(r, g) {
    var val = convert(r) + convert(g);
    hexToBase64(val);
    setHexValue(val);
  }

  // var hex = toHexFunction(15, 50);

  // console.log("helloo", hex)
  // console.log("base64", hexToBase64("000f0032"))
  function hexToBase64(bytes) {
    const toBase64 = btoa(
      bytes
        .match(/\w{2}/g)
        .map(function (a) {
          return String.fromCharCode(parseInt(a, 16));
        })
        .join("")
    );
    setBase64(toBase64);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const copyToClipboard = (val) => {
    window.navigator.clipboard.writeText(val);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  function isMultiple(multiple, factor) {
    console.log("multple", multiple);
    console.log("factor", factor);
    console.log("multiple % factor", multiple % factor);
    if (multiple % factor === 0) {
      return true;
    } else {
      return false;
    }
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="container" style={{ marginTop: 150 }}>
      <div>
        <h2 style={{ textAlign: "center" }}>
          Configuration Response Downlink Encoder
        </h2>
        <p style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
          This tool can be used to generate downlink payloads to reconfigure the
          NanoTag's Record and Report periods.
        </p>
        <Container style={{}}>
          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              <TextField
                autoComplete="off"
                required
                label="Record period"
                id="outlined-start-adornment"
                sx={{
                  m: 1,
                  width: "50ch",
                  "& label": {
                    // fontFamily: 'OpenSans',
                  },
                }}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, "");

                  setRecordPeriod(newValue);
                  if (newValue == "" || newValue == "0") {
                    setRecordPeriodError(true);
                  } else {
                    if (isMultiple(reportPeriod, newValue)) {
                      setRecordPeriodError(false);
                      setReportPeriodError(false);
                      setMultipleError(false);
                      toHexFunction(newValue, reportPeriod);
                    } else {
                      setReportPeriodError(true);
                      setRecordPeriodError(true);
                      setMultipleError(true);
                    }
                    // hexToBase64(e.target.value)
                    // Decoder(e.target.value, port, packetSelectedType, base64)
                  }
                }}
                error={recordPeriodError}
                value={recordPeriod}
              />
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={8000}
                title="Frequency that the NanoTag records a temperature reading (Minimum of 10 seconds)."
              >
                <IconButton>
                  <QuestionMark />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className={classes.textFieldView}>
            <div
              className="col-lg-5 d-flex container"
              style={{ alignItems: "center" }}
            >
              <TextField
                autoComplete="off"
                required
                label="Report period"
                id="outlined-start-adornment"
                sx={{
                  m: 1,
                  width: "50ch",
                  "& label": {
                    // fontFamily: 'OpenSans',
                  },
                }}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, "");
                  setReportPeriod(newValue);
                  if (newValue == "" || newValue == "0") {
                    setReportPeriodError(true);
                  } else {
                    if (isMultiple(newValue, recordPeriod)) {
                      setReportPeriodError(false);
                      setRecordPeriodError(false);
                      setMultipleError(false);
                      toHexFunction(recordPeriod, newValue);
                    } else {
                      setReportPeriodError(true);
                      setRecordPeriodError(true);
                      setMultipleError(true);
                    }

                    // hexToBase64(e.target.value)
                    // Decoder(e.target.value, port, packetSelectedType, base64)
                  }
                }}
                error={reportPeriodError}
                value={reportPeriod}
              />
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={8000}
                title="Frequency that the NanoTag transmits temperature readings (Minimum of 30 seconds for US915, AU915, and AS923; Minimum of 2 minutes for EU868)."
              >
                <IconButton>
                  <QuestionMark />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {multipleError && (
            <h6 className="text-center" style={{ color: "red", marginTop: 5 }}>
              Report Period must be a multiple of Record Period
            </h6>
          )}
          {!multipleError &&
          reportPeriod !== "" &&
          reportPeriod != null &&
          recordPeriod !== "" &&
          recordPeriod != null &&
          reportPeriod !== "0" &&
          recordPeriod !== "0" ? (
            <>
              <div
                className={`${classes.rowStyle} d-flex align-items-center justify-content-center`}
              >
                <h5 className="text-center" style={{ marginRight: 10 }}>
                  Hex: {hexValue.toUpperCase()}
                </h5>
                <button
                  className={classes.buttonStyle}
                  onClick={() => copyToClipboard(hexValue.toUpperCase())}
                >
                  <ContentCopyIcon className={classes.copyStyle} />
                </button>
              </div>

              <div
                className={`${classes.rowStyle} d-flex align-items-center justify-content-center`}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <h5 className="text-center" style={{ marginRight: 10 }}>
                  Base64: {base64}
                </h5>
                <button
                  className={classes.buttonStyle}
                  onClick={() => copyToClipboard(base64)}
                >
                  {" "}
                  <ContentCopyIcon className={classes.copyStyle} />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={classes.displayFlex}>
                <h5 className="text-center" style={{ marginRight: 10 }}>
                  Hex: N/A
                </h5>
              </div>

              <div
                className={classes.displayFlex}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <h5 className="text-center" style={{ marginRight: 10 }}>
                  Base64: N/A
                </h5>
              </div>
            </>
          )}

          <div className={classes.textFieldView}>
            <div style={{ textAlign: "center" }}>
              <ul
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  listStyleType: "disc",
                }}
              >
                <li>Report Period must be a multiple of Record Period</li>
                <li>Use Port 28 for minutes and Port 29 for seconds</li>
                <li>Use Base64 value on Helium network</li>
                <li>Confirmed downlinks are recommended on Helium network</li>
              </ul>
            </div>
          </div>
          <Snackbar
            open={modalOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Copied to clipboard"
            action={action}
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
          />
        </Container>
      </div>
    </div>
  );
};
export default ConfigurationEncoder;
