import React, { useEffect } from "react";
import nanoTag from "../../assets/image/use-cases/NanotagImage.png";
import predective from "../../assets/image/use-cases/predictive-maintenance.png";
import styles from "./CaseStudiesStyle";
const Predective = () => {
  return (
    <>
      <div className="sub-page-title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h2 style={styles.title}>Case Study: Predictive Maintenance</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-4">
        <p className="predictive-tag-text">Problem</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                A Fortune 100 company was losing millions of dollars per year
                due to motorized equipment failures. Their distribution centers
                and retail stores rely heavily on motorized machinery like
                conveyor belts, gear boxes, pumps, refrigerators, etc. A single
                point of failure could cripple a facility’s operations for days.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5" style={{ textAlign: "center" }}>
        <img src={nanoTag} style={{ width: 300, height: 300 }} />
      </div>
      <div className="predictive-tag col-xl-4 col-lg-4 col-md-4">
        <p className="predictive-tag-text">Objective & Approach</p>
      </div>
      <div className="mt-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <div className="content section__heading">
                <p style={styles.text}>
                  The Fortune 100 company knew that motors typically overheat
                  prior to failure. They figured that attaching a connected
                  temperature sensor on each motor would allow them to remotely
                  monitor motor health and get ahead of equipment failures. The
                  company researched WiFi and Bluetooth temperature sensors, but
                  they determined that the read range was too limited. They also
                  determined that cellular was far too expensive, and wired
                  solutions would be too burdensome to install and maintain. So
                  they decided to leverage a LoRaWAN sensor for its long range
                  (100X the range of WiFi and Bluetooth), low power (long
                  battery life), and low connectivity cost. Of the various
                  temperature sensors in the LoRaWAN ecosystem, they chose
                  NanoTags because they are the smallest, lowest cost, and only
                  disposable sensor in the LoRaWAN ecosystem.{" "}
                </p>
                <p style={styles.text} className="mt-3">
                  As a test, they applied NanoTags to every motor in a
                  distribution center. NanoTags were configured to send a
                  temperature reading every hour. They learned that a healthy
                  motor should run at around 125°F, and a motor in need of
                  immediate attention would spike in temperature. They added a
                  high temperature threshold of 140°F. Thus, if a NanoTag ever
                  reported a temperature higher than 140°F, an alarm would
                  trigger in their system, alerting them that a motor was in
                  immediate need of attention.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="content-img">
                <img src={predective} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-5">
        <p className="predictive-tag-text">Results</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-9 col-lg-9 col-md-9">
            <div className="content section__heading">
              <p style={styles.text}>
                Within one year, the NanoTag experiment had saved six motors
                from imminent failure. Each time a temperature spike occurred,
                mechanics were able to quickly service the motor before
                irreparable damage occurred.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="mt-4  mb-5">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-2 col-md-2 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>ROI:</h5>
                    <h5 style={styles.bold}>50,000%</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>EQUIPMENT COST SAVINGS:</h5>
                    <h5 style={styles.bold}>$100,000</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>TOTAL COST SAVINGS:</h5>
                    <h5 style={styles.bold}>&gt;$500,000</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Predective;
