import React, { useEffect } from "react";
import shape2 from "../assets/image/l1/shape2.svg";
import blackShape from "../assets/image/l1/price-black-shape.svg";
const AboutUs = () => {
  return (
    <>
      <div className="about-title content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#F9FAFB" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
                <h1>Who We Are</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={blackShape} alt="" className="w-100" />
        </div>
      </div>

      <div className="content-area-l-12-1 pt-3">
        <div className="container">
          <div className="row align-items-center justify-content-center row-content">
            <div className="col-12 col-lg-10 col-xxl-9">
              {/* <div className="content section__heading text-center">
                <h2>Our Mission</h2>
                <p>
                  We started the open license hardware model because our mission
                  is to empower the world with the lowest cost LoRa sensor. By
                  creating an open hardware ecosystem, we’ve laid the groundwork
                  to accomplish three things.
                  <br />
                  <br />
                  First, open licensing of the NanoTag has removed any incentive
                  for us to make money off of NanoTags. Instead, we and all
                  other ecosystem partners are working in unison to drive down
                  costs across the board for our collective benefit.
                  <br />
                  <br />
                  Second, we believe the only way to survive in this world is to
                  innovate faster than everyone else. Time and time again it’s
                  been proven that open ecosystems innovate faster than closed
                  systems.
                  <br />
                  <br />
                  And finally, our open ecosystem model provides a platform for
                  endless new IoT use cases that we don’t have the industry
                  expertise or bandwidth to support on our own. We rely on our
                  partners to drive new use cases.
                  <br />
                  <br />
                  We welcome you to join our growing family!
                </p>
              </div> */}

              <div className="content section__heading text-center">
                NanoThings is a sensor protocol company. We’ve developed the
                most advanced LoRaWAN sensor protocol in the industry. The
                NanoThings Sensor Standard can be used by most sensors for out
                of the box enterprise grade temperature monitoring and location
                tracking applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
