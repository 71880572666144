import React, { useEffect, useState } from "react";
import realTimePdf from "../assets/pdf/nanotag-real-time-data-sheet.pdf";
import closedDataPdf from "../assets/pdf/nanotag-closed-loop-data-sheet.pdf";
import shape2 from "../assets/image/l1/shape2.svg";
import buttonLess from "../assets/image/nano/7.svg";
import activation from "../assets/image/nano/8.svg";
import priceShape from "../assets/image/l1/price-black-shape.svg";
import flexible from "../assets/image/nano/9.svg";
import fullBattery from "../assets/image/nano/battery/6.svg";
import halfBattery from "../assets/image/nano/battery/4.svg";
import notfullBattery from "../assets/image/nano/battery/5.svg";
import emptyBattery from "../assets/image/nano/battery/2.svg";
import wifi from "../assets/image/nano/wifi/6.svg";
import wifi2 from "../assets/image/nano/wifi/2.svg";
import wifi3 from "../assets/image/nano/wifi/3.svg";

import image1 from "../assets/image/nano/album/0157.jpg";
import angled from "../assets/image/nano/album/0157angled.jpg";
import duoMedium from "../assets/image/nano/album/9713_duo-medium.jpg";

import usecase1 from "../assets/image/nano/1.svg";
import usecase2 from "../assets/image/nano/2.svg";
import usecase3 from "../assets/image/nano/3.svg";
import usecase4 from "../assets/image/nano/4.svg";
import usecase5 from "../assets/image/nano/5.svg";
import usecase6 from "../assets/image/nano/6.svg";

import Fancybox from "fancybox";
import $ from "jquery"; // Import jQuery
import "jquery-touchswipe";

import Slider from "react-slick";

const NanoTag = () => {
  const [currentImg, setCurrentImg] = useState(1); // Initial image number

  useEffect(() => {
    const num = 20; // the total number of images

    // Preload all the images into a hidden div
    for (let i = 1; i <= num; i++) {
      const img = new Image();
      img.src = `../assets/image/nano/view/tag_${i}.png`;
      document.getElementById("preload-imgs").appendChild(img);
    }

    // Control swipes using jquery.touchSwipe.min.js
    const swipeOptions = {
      triggerOnTouchEnd: true,
      swipeStatus: swipeStatus,
      allowPageScroll: "vertical",
      threshold: 75,
    };

    const imgs = $(".img-container"); // the element that will be swipeable
    imgs.swipe(swipeOptions);

    function swipeStatus(event, phase, direction, distance) {
      if (direction === "left") {
        changeImg(distance);
      } else if (direction === "right") {
        changeImgR(-distance);
      }
    }

    function changeImg(imgNum) {
      // Divide by 8 (or any number) to spread it out so it doesn't load a new img every single px of swipe distance
      imgNum = Math.floor(imgNum / 8);

      let newImgNum = currentImg + imgNum;

      if (newImgNum < 1) {
        newImgNum += num;
      }
      if (newImgNum > num) {
        newImgNum -= num;
      }

      // Set the current image number
      setCurrentImg(newImgNum);
    }

    function changeImgR(imgNum) {
      // Divide by 8 (or any number) to spread it out so it doesn't load a new img every single px of swipe distance
      imgNum = Math.floor(imgNum / 12);

      let newImgNum = currentImg + imgNum;

      const num2 = -Math.abs(num);
      if (newImgNum > num2) {
        newImgNum += num;
      }
      if (newImgNum <= num2) {
        newImgNum += num * 2;
      }

      // Set the current image number
      setCurrentImg(newImgNum);
    }
  }, []); // Empty dependency array to run this effect only once

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      Thumbs: {
        Carousel: {
          Sync: {
            friction: 0.9,
          },
        },
      },
    });
  }, []);

  const slider = React.useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: <button>Previous</button>, // Replace with your custom arrow component
    nextArrow: <button>Next</button>, // Replace with your custom arrow component
  };
  const [activeArrow, setActiveArrow] = useState(0);

  const handleArrowClick = (index) => {
    if (index == 0) {
      slider?.current?.slickPrev();
    } else {
      slider?.current?.slickNext();
    }
    setActiveArrow(index);
  };

  return (
    <>
      <div className="hero-area-l1-no-img nanotag-page nanotag-bg position-relative background-property">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 order-lg-1 order-1">
              <div className="content text-center">
                <h2>The NanoTag</h2>
                {/* <p>
                  The world’s smallest, lowest cost, and most versatile location
                  tracker and temperature sensor
                </p> */}
                <p>
                  We invented the NanoTag in 2017 to prove to the world that
                  sensors could be made smaller and cheaper without compromising
                  quality. Now we are proud to open source the NanoTag for the
                  world to use and build upon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sub-page-title"
        style={{ paddingTop: 30, paddingBottom: 30, backgroundColor: "white" }}
        // style="padding-top: 30px; padding-bottom: 30px;background-color:white !important;"
      >
        <div className="container">
          <div className="row justify-content-center mt-lg-5 mb-lg-5 mt-4 mb-2">
            <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-11">
              <div className="section-heading-14 text-center">
                <div className="nano-slider">
                  <Slider
                    {...settings}
                    className="owl-theme"
                    ref={slider}
                    style={{ zIndex: 1000 }}
                  >
                    <div className="logo-title img-nano-title">
                      <img src={image1} alt="" />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img src={angled} alt="" />
                    </div>

                    <div className="logo-title img-nano-title">
                      <img src={duoMedium} alt="" />
                    </div>

                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/9713_side-medium.jpg")}
                        alt=""
                      />
                    </div>

                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/0181v2.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/0157frontv2.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/0159.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/0159v2.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/img-1.jpeg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/1.jpeg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/2.jpeg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/3.jpeg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/4.jpeg")}
                        alt=""
                      />
                    </div>
                    <div className="logo-title img-nano-title">
                      <img
                        src={require("../assets/image/nano/album/5.jpeg")}
                        alt=""
                      />
                    </div>
                  </Slider>
                </div>
                {/* 
                <div className="thumb-nail-nano owl-carousel owl-theme">
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0157.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0157angled.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0181v2.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0157frontv2.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0159.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/0159v2.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/img-1.jpeg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/1.jpeg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/2.jpeg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/3.jpeg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/4.jpeg")}
                      alt=""
                    />
                  </div>
                  <div className="logo-title img-nano-title">
                    <img
                      src={require("../assets/image/nano/album/5.jpeg")}
                      alt=""
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-area-l8 pb-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center">
                <h2>Build Your Solution Around the NanoTag</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="container-view">
                <div className="img-container">
                  <div className="img360">
                    <img
                      id="myImg"
                      src={require(`../assets/image/nano/view/tag_${currentImg}.png`)}
                      alt="NanoTag"
                    />
                  </div>
                </div>
                <div className="caption"></div>
                <div id="preload-imgs"></div>
                <p className="text-center fst-italic">
                  (Hold image and pan left or right to rotate the NanoTag)
                </p>
              </div>
            </div>
            <div className="offset-xl-1 col-xl-6 col-lg-7 col-md-9">
              <div className="content section__heading">
                <h4>Hybrid Printed Manufacturing</h4>
                <p>
                  NanoTags are manufactured with an innovative hybrid printing
                  process. Developed and patented by NanoThings, hybrid printing
                  is a manufacturing process that enables 10X the scalability of
                  traditional electronics manufacturing. Key components, like
                  the antenna and connective circuitry, are printed on a
                  flexible substrate to reduce size and cost while enabling
                  mechanical flexibility. Non printable components, like the
                  silicon and an ultra thin flexible battery, are surface
                  mounted after the printing process.
                  <br />
                  The result is a device with a significantly smaller form
                  factor, carbon footprint, and cost to produce.{" "}
                </p>
                <div className="border-top mt-3 mb-3"></div>
                <h4>LoRaWAN Ready</h4>
                <p>
                  The NanoTag has been extensively tested and commercially
                  deployed across LoRaWAN in North America, Europe, and Asia.
                  For North America, the NanoTag also comes FCC certified*.
                </p>
                <p className="small-content">
                  *Any changes to the antenna, chip, or any other RF components
                  of the NanoTag may void FCC certification.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#fff" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
      <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
              <h2>Key Form Factor Features</h2>
              </div>
            </div>
          </div>
          <div className="container">
          <div className="row nano-row feature-l-11-items justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="d-flex flex-column justify-content-center">
                <div className="icon-box">
                  <img src={buttonLess} alt="" />
                </div>

                <div className="content-body">
                  <h5>Buttonless</h5>
                  <p>
                    Buttons add cost and user confusion. Users are 90% more
                    likely to configure a device incorrectly when buttons are
                    present.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex flex-column justify-content-center">
                <div className="icon-box">
                  <img src={activation} alt="" />
                </div>
                <div className="content-body">
                  <h5>Activation Tab</h5>
                  <p>
                    The activation tab has a dual purpose. First and foremost,
                    removing the activation tab activates the NanoTag. Secondly,
                    the activation tab can be attached to paperwork as proof of
                    use.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex flex-column justify-content-center">
                <div className="icon-box">
                  <img src={flexible} alt="" />
                </div>
                <div className="content-body">
                  <h5>Flexible Substrate</h5>
                  <p>
                    100 micron thick PET film provides mechanical flexibility
                    without compromising tensile strength or durability.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={priceShape} alt="" className="w-100" />
        </div>
      </div>


      <div className="service-area-l4-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <h2>Standard NanoTag Specifications</h2>
              </div>
            </div>
          </div>
          <div className="row service-l4-card-items">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Temperature Sensor</h4>
                  <ul>
                    <li>
                      <strong>Operating Range:</strong> -30°C to 60°C
                    </li>
                    <li>
                      <strong>Typical Accuracy (max):</strong> ± 0.5 °C (-10 °C
                      to +60 °C)
                    </li>
                    <li>
                      <strong>Resolution:</strong> 0.1°C
                    </li>
                    <li>
                      <strong>Notes:</strong> The NanoTag can be equipped with
                      different temperature sensors. A full list of compatible
                      sensors is available in the NanoTag integration
                      documentation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Battery</h4>
                  <ul>
                    <li>
                      <strong>Capacity:</strong> 600mAh
                    </li>
                    <li>
                      <strong>Chemistry:</strong> LiMnO2
                    </li>
                    <li>
                      <strong>Safety Standards:</strong> IEC 60086-4/GB 8897.4,
                      IEC 62281
                    </li>
                    <li>
                      <strong>Notes:</strong> The NanoTag can operate on any
                      battery chemistry that meets the minimum supply voltage
                      requirements.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Memory</h4>
                  <ul>
                    <li>
                      <strong>Memory Type:</strong> Non-volatile flash
                    </li>
                    <li>
                      <strong>Storage Capacity (NanoTag Closed Loop):</strong>{" "}
                      24,000 temperature readings
                    </li>
                    <li>
                      <strong>Storage Capacity (NanoTag Real Time):</strong>{" "}
                      2,000 temperature readings on a rolling buffer
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Dimensions</h4>
                  <ul>
                    <li>
                      <strong>Measurements (max):</strong> 98 x 130 x 1.5 (mm)
                    </li>
                    <li>
                      <strong>Weight:</strong> 6.5 grams
                    </li>
                    <li>
                      <strong>Notes:</strong> Thickness of the NanoTag varies
                      between 200 micron and 1.5mm. The thickest elements of the
                      NanoTag are the SIP and battery.{" "}
                    </li>
                  </ul>
                  <p>Dimensions can be modified. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Dimensions</h4>
                  <ul>
                    <li>
                      <strong>Inner Film:</strong> PET{" "}
                    </li>
                    <li>
                      <strong>Release Liner:</strong> 3M 467MP
                    </li>
                    <li>
                      <strong>Top Laminate:</strong> Neschen Solvoprint
                      Performance 80 Nolite
                    </li>
                    <li>
                      <strong>Notes:</strong> Although the NanoTag is flexible,
                      it is not recommended to bend the NanoTag.
                    </li>
                  </ul>
                  <p>
                    The NanoTag can be manufactured without release liner for
                    applications that require easy removal or direct contact
                    with food or water.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Communication Interface</h4>
                  <ul>
                    <li>
                      <strong>Protocol:</strong> LoRaWAN
                    </li>
                    <li>
                      <strong>Regions:</strong> US, Canada, Europe, Australia,
                      Japan
                    </li>
                    <li>
                      <strong>Band:</strong> Sub-GHz ISM
                    </li>
                    <li>
                      <strong>Output Power (max):</strong> 20dBm
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-12 text-center pt-3">
              <h3>Download the Data Sheets:</h3>
              <br />
              <a
                target="_blank"
                className="btn btn-style-02 focus-reset  mr-2 download-btn"
                href={realTimePdf}
              >
                NanoTag Real Time
              </a>
              <a
                target="_blank"
                className="btn btn-style-02 focus-reset download-btn"
                href={closedDataPdf}
              >
                NanoTag Closed Loop
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <div className="content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#fff" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
                <h2>A Device With Endless Use Cases</h2>
              </div>
            </div>
          </div>
          <div className="row feature-l-11-items justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase1} alt="" />
                </div>
                <div className="content-body">
                  <h5>Cold Chain Monitoring</h5>
                  <p>
                    Cold chain monitoring for food, beverages, pharmaceuticals,
                    chemicals, and more.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase2} alt="" />
                </div>
                <div className="content-body">
                  <h5>Inventory Monitoring</h5>
                  <p>Autonomous, scalable inventory monitoring.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase3} alt="" />
                </div>
                <div className="content-body">
                  <h5>Asset Tracking</h5>
                  <p>
                    Cost effective asset tracking without heavy installations.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase4} alt="" />
                </div>
                <div className="content-body">
                  <h5>Parcel Tracking</h5>
                  <p>
                    Anti-tampering protection, open notifications, and confirmed
                    arrivals made possible.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase5} alt="" />
                </div>
                <div className="content-body">
                  <h5>Smart Packaging</h5>
                  <p>
                    Smart packaging made possible. Especially great for reusable
                    packaging.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="d-flex ">
                <div className="icon-box">
                  <img src={usecase6} alt="" />
                </div>
                <div className="content-body">
                  <h5>Facility Monitoring</h5>
                  <p>Simple and scalable facility temperature mapping.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={priceShape} alt="" className="w-100" />
        </div>
      </div>

      <div className="service-area-l4-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <h2> The NanoTag vs. Other Devices</h2>
              </div>
            </div>
          </div>
          <div className="row service-l4-card-items justify-content-center">
            <div className="col-12 col-xl-10">
              <div className="table-responsive table-nanotag">
                <table className="table text-successtable-border border-light">
                  <thead className="border-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col" className="title-table nanotag-active">
                        <strong>NanoTag</strong>
                      </th>
                      <th scope="col" className="title-table">
                        <strong>
                          Analog Data
                          <br /> Loggers
                        </strong>
                      </th>
                      <th scope="col" className="title-table">
                        <strong>
                          NFC
                          <br /> Tags
                        </strong>
                      </th>
                      <th scope="col" className="title-table">
                        <strong>
                          RFID
                          <br /> Tags
                        </strong>
                      </th>
                      <th scope="col" className="title-table">
                        <strong>
                          Bluetooth
                          <br /> Devices
                        </strong>
                      </th>
                      <th scope="col" className="title-table">
                        <strong>
                          Cellular
                          <br /> Devices
                        </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="7" className="title-box">
                        Capabilities
                      </td>
                      <th className="title-column" scope="row">
                        Temperature Monitoring
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Impact/Fall/Tilt Monitoring
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Autonomous Arrival & Departure Tracking
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Autonomous Real Time Yard Monitoring
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Autonomous Real Time In-Facility Monitoring
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        First/Last Mile Location Tracking
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-check"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Multi-Leg Tracking
                      </th>
                      <td className="nanotag-active">
                        <i className="fas fa-check"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                      <td>
                        <i className="fas fa-times"></i>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row"></th>
                      <td></td>
                      <td className="nanotag-active"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td rowSpan="4" className="title-box">
                        Attributes
                      </td>
                      <th className="title-column" scope="row">
                        Size
                      </th>
                      <td className="nanotag-active">Small</td>
                      <td>Medium</td>
                      <td>Small</td>
                      <td>Small</td>
                      <td>Medium</td>
                      <td>Large</td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Battery Life
                      </th>
                      <td className="nanotag-active">
                        <div className="icon-btr">
                          <img src={fullBattery} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={halfBattery} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">N/A</div>
                      </td>
                      <td>
                        <div className="icon-btr">N/A</div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={notfullBattery} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={emptyBattery} alt="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Wireless Range
                      </th>
                      <td className="nanotag-active">
                        <div className="icon-btr">
                          <img src={wifi} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">N/A</div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={wifi2} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={wifi2} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={wifi3} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="icon-btr">
                          <img src={wifi} alt="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="title-column" scope="row">
                        Cost
                      </th>
                      <td className="nanotag-active">$</td>
                      <td>$$</td>
                      <td>$$</td>
                      <td>$</td>
                      <td>$$</td>
                      <td>$$$$</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
<script src={require("https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.js"></script>
<script>
  $(function () {

    var num = 20; // the total number of images 

    // Preload all the images into hidden div
    for (var i = 1; i <= num; i++) {
      var img = document.createElement('img');
      img.src = '../assets/image/nano/view/tag_' + i + '.png';
      document.getElementById('preload-imgs').appendChild(img);
    }

    // Control swipes using jquery.touchSwipe.min.js
    // http://labs.rampinteractive.co.uk/touchSwipe/
    var swipeOptions =
    {
      triggerOnTouchEnd: true,
      swipeStatus: swipeStatus,
      allowPageScroll: "vertical",
      threshold: 75
    }

    $(function () {
      imgs = $(".img-container"); // the element that will be swipeable
      imgs.swipe(swipeOptions);
    });

    function swipeStatus(event, phase, direction, distance) {
      var duration = 0;
      if (direction == "left") {
        changeImg(distance);
      }
      else if (direction == "right") {
        changeImgR(-distance);
      }
    }

    function changeImg(imgNum) {

      // divide by 8 (or any number) to spread 
      // it out so it doesn't load new img 
      // every single px of swipe distance
      imgNum = Math.floor(imgNum / 8);

      if (imgNum < 1) {
        imgNum += num;
      }
      if (imgNum > num) {
        imgNum -= num;
      }

      // change the image src
      document.getElementById("myImg").src = "../assets/image/nano/view/tag_" + imgNum + ".png";
    }

    function changeImgR(imgNum) {

      // divide by 8 (or any number) to spread 
      // it out so it doesn't load new img 
      // every single px of swipe distance
      imgNum = Math.floor(imgNum / 12);

      var num2 = -Math.abs(num);
      if (imgNum > num2) {
        imgNum += num;
      }
      if (imgNum <= num2) {
        imgNum += num * 2;
      }

      // change the image src
      document.getElementById("myImg").src = "../assets/image/nano/view/tag_" + imgNum + ".png";
    }
  })

  Fancybox.bind("[data-fancybox]", {
    Thumbs: {
      Carousel: {
        Sync: {
          friction: 0.9
        }
      },
    },
  });
</script> */}
    </>
  );
};
export default NanoTag;
