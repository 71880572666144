import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ReactLoading from "react-loading";
import QuestionMark from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import useStyles from "./CalculatorStyle";
import { Container } from "@material-ui/core";
import txTimeUSJson from "../../../components/BatteryData/txTimeUS.json";
import txTimeEUJson from "../../../components/BatteryData/txTimeEU.json";
import txTimeAUJson from "../../../components/BatteryData/txTimeAU.json";
import txTimeASJson from "../../../components/BatteryData/txTimeAS.json";
const Calculator = () => {
  const classes = useStyles();

  useEffect(() => {
    setShelfDays(localStorage.getItem("shelfDays"));
    setActiveTime(localStorage.getItem("activeTime"));
    setTemperature(localStorage.getItem("temperature"));
    setTemperaturePeriod(localStorage.getItem("temperaturePeriod"));
    setTemperatureReporting(localStorage.getItem("temperatureReporting"));
    setLastPartialPacketSize(localStorage.getItem("lastPartialPacket"));
    setDataRate(localStorage.getItem("dataRate"));
    setRegion(localStorage.getItem("region"));
    switch (region) {
      case "US915":
        loopJsonObject(
          localStorage.getItem("dataRate"),
          localStorage.getItem("lastPartialPacket"),
          txTimeUSJson
        );
        break;
      case "EU868":
        loopJsonObject(
          localStorage.getItem("dataRate"),
          localStorage.getItem("lastPartialPacket"),
          txTimeEUJson
        );
        break;
      case "AU915":
        loopJsonObject(
          localStorage.getItem("dataRate"),
          localStorage.getItem("lastPartialPacket"),
          txTimeAUJson
        );
        break;
      case "AS923":
        loopJsonObject(
          localStorage.getItem("dataRate"),
          localStorage.getItem("lastPartialPacket"),
          txTimeASJson
        );
        break;

      default: // Do nothing, should be unreachable
    }

    if (
      localStorage.getItem("region") == "US915" &&
      localStorage.getItem("temperatureReporting") < 0.5
    ) {
      setTemperatureReportingError(true);
    } else if (
      localStorage.getItem("region") == "EU868" &&
      localStorage.getItem("temperatureReporting") < 2
    ) {
      setTemperatureReportingError(true);
    } else if (
      localStorage.getItem("region") == "AS923" &&
      localStorage.getItem("temperatureReporting") < 0.166
    ) {
      setTemperatureReportingError(true);
    } else {
      setTemperatureReportingError(false);
    }

    if (
      // localStorage.getItem("temperaturePeriod") == "" ||
      parseFloat(localStorage.getItem("temperaturePeriod")) < 0.166 ||
      parseFloat(localStorage.getItem("temperaturePeriod")) > 65535
    ) {
      setTemperaturePeriodError(true);
    } else if (
      localStorage.getItem("temperaturePeriod") >
      parseInt(localStorage.getItem("temperatureReporting"))
    ) {
      setTemperatureReportingError(true);
      setTemperaturePeriodError(true);
    } else {
      setTemperatureReportingError(false);
      setTemperaturePeriodError(false);
    }

    setTxTimeValue(localStorage.getItem("txTimeValueLocal"));
    handleCalculation(
      localStorage.getItem("shelfDays"),
      localStorage.getItem("temperature"),
      localStorage.getItem("temperaturePeriod"),
      localStorage.getItem("temperatureReporting"),
      localStorage.getItem("txTimeValueLocal"),
      localStorage.getItem("dataRate"),
      localStorage.getItem("region")
    );
  }, []);
  const [loading, setLoading] = useState(false);

  const allDataRate = [
    {
      value: "DR0",
      label: "DR0",
    },
    {
      value: "DR1",
      label: "DR1",
    },
    {
      value: "DR2",
      label: "DR2",
    },
  ];

  const allDataRateEU = [
    {
      value: "DR2",
      label: "DR2",
    },
    {
      value: "DR3",
      label: "DR3",
    },
    {
      value: "DR4",
      label: "DR4",
    },
  ];

  const allRegions = [
    {
      value: "US915",
      label: "US915",
    },
    {
      value: "EU868",
      label: "EU868",
    },

    {
      value: "AU915",
      label: "AU915",
    },

    {
      value: "AS923",
      label: "AS923",
    },
  ];
  const [dataRate, setDataRate] = React.useState(
    localStorage.getItem("dataRate")
  );
  const [region, setRegion] = React.useState(localStorage.getItem("region"));

  const handleChangeDataRate = (event) => {
    setDataRate(event.target.value);
    localStorage.setItem("dataRate", event.target.value);

    switch (region) {
      case "US915":
        loopJsonObject(event.target.value, lastPartialPacketSize, txTimeUSJson);
        break;
      case "EU868":
        loopJsonObject(event.target.value, lastPartialPacketSize, txTimeEUJson);
        break;
      case "AU915":
        loopJsonObject(event.target.value, lastPartialPacketSize, txTimeAUJson);
        break;
      case "AS923":
        loopJsonObject(event.target.value, lastPartialPacketSize, txTimeASJson);
        break;
      // case 'AS923':
      //     loopJsonObject(event.target.value, lastPartialPacketSize, txTimeEUJson)
      //     break;

      default: // Do nothing, should be unreachable
    }
  };

  function loopJsonObject(value, lastPartialSize, jsonObject) {
    let x = null;
    Object.keys(jsonObject).forEach((item) => {
      if (value === item) {
        for (let i = 0; i < jsonObject[item].length; i++) {
          // console.log(jsonObject[item][i])
          if (
            jsonObject[item][i].samplesPerPacketVal ===
            parseInt(lastPartialSize)
          ) {
            x = jsonObject[item][i].dataRateVal;
          }
        }
      }
    });
    localStorage.setItem("txTimeValueLocal", x);
    handleCalculation(
      shelfDays,
      temperature,
      temperaturePeriod,
      temperatureReporting,
      x,
      value,
      region
    );
    setTxTimeValue(x);

    // console.log(x)
  }

  const [shelfDays, setShelfDays] = useState("");
  const [shelfDaysError, setShelfDaysError] = useState(false);

  const [temperature, setTemperature] = useState("");
  const [temperatureError, setTemperatureError] = useState(false);

  const [temperaturePeriod, setTemperaturePeriod] = useState("");
  const [temperaturePeriodError, setTemperaturePeriodError] = useState(false);

  const [temperatureReporting, setTemperatureReporting] = useState("");
  const [temperatureReportingError, setTemperatureReportingError] =
    useState(false);

  const [activeTime, setActiveTime] = useState("");
  const [activeTimeError, setActiveTimeError] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [finalResult, setFinalResult] = useState();

  const [samplesPerDataReport, setSamplesPerDataReport] = useState();
  const [packetsPerDataReport, setPacketsPerDataReport] = useState();
  const [fullPacketCount, setFullPacketCount] = useState();
  const [lastPartialPacketSize, setLastPartialPacketSize] = useState();
  const [health, setHealth] = useState();
  const [s1Check, sets1Check] = useState();
  const [sleep, setSleep] = useState();
  const [oneTimeCharge, setOneTimeCharge] = useState();
  const [temperatureSamplingPerDay, setTemperatureSamplingPerDay] = useState();
  const [fullPacketReportingCharge, setFullPacketReportingCharge] = useState();
  const [partialPacketReportingCharge, setPartialPacketReportingCharge] =
    useState();
  const [
    temperatureReportingChargePerDay,
    setTemperatureReportingChargePerDay,
  ] = useState();
  const [finalCapacity, setFinalCapacity] = useState();
  const [temperatureDataRecovery, setTemperatureDataRecovery] = useState();
  const [dailyChargeConsumption, setDailyChargeConsumption] = useState();
  const [txTimeValue, setTxTimeValue] = useState();

  async function handleCalculation(
    shelfValue,
    tempValue,
    tempPeriodValue,
    tempReportingValue,
    txTimeValue,
    dataRateValue,
    regionValue
  ) {
    let sampleDataReport = Math.ceil(tempReportingValue / tempPeriodValue);
    setSamplesPerDataReport(sampleDataReport);
    let maxSamplesPerPacket;
    let fullPacketCharge;
    if (regionValue == "US915") {
      switch (dataRateValue) {
        case "DR0":
          maxSamplesPerPacket = 4;
          fullPacketCharge = 51.5;
          break;
        case "DR1":
          maxSamplesPerPacket = 24;
          fullPacketCharge = 53.8;
          break;
        case "DR2":
          maxSamplesPerPacket = 60;
          fullPacketCharge = 54.97;
          break;
        default: // Do nothing, should be unreachable
      }
    }
    if (regionValue == "AU915") {
      switch (dataRateValue) {
        case "DR2":
          maxSamplesPerPacket = 4;
          fullPacketCharge = 51.5;
          break;
        case "DR3":
          maxSamplesPerPacket = 24;
          fullPacketCharge = 53.8;
          break;
        case "DR4":
          maxSamplesPerPacket = 60;
          fullPacketCharge = 54.97;
          break;
        default: // Do nothing, should be unreachable
      }
    }

    if (regionValue == "EU868") {
      switch (dataRateValue) {
        case "DR2":
          maxSamplesPerPacket = 24;
          fullPacketCharge = 50.59;
          break;
        case "DR3":
          maxSamplesPerPacket = 55;
          fullPacketCharge = 48.0;
          break;
        case "DR4":
          maxSamplesPerPacket = 111;
          fullPacketCharge = 47.96;
          break;
        default: // Do nothing, should be unreachable
      }
    }

    if (regionValue == "AS923") {
      switch (dataRateValue) {
        case "DR2":
          maxSamplesPerPacket = 24;
          fullPacketCharge = 63.9;
          break;
        case "DR3":
          maxSamplesPerPacket = 55;
          fullPacketCharge = 60.06;
          break;
        case "DR4":
          maxSamplesPerPacket = 111;
          fullPacketCharge = 60.01;
          break;
        default: // Do nothing, should be unreachable
      }
    }

    //bel word mahtouta l 3akes
    let packetDataReport = Math.ceil(
      parseInt(sampleDataReport) / parseInt(maxSamplesPerPacket)
    );
    setPacketsPerDataReport(packetDataReport);

    //bel word mahtouta l 3akes
    let fullPacket = Math.floor(
      parseInt(sampleDataReport) / parseInt(maxSamplesPerPacket)
    );
    setFullPacketCount(fullPacket);

    let lastPartialPacket = sampleDataReport % maxSamplesPerPacket;
    setLastPartialPacketSize(lastPartialPacket);
    localStorage.setItem("lastPartialPacket", lastPartialPacket);
    //changing region

    // switch (regionValue) {
    //     case 'US915':
    //         loopJsonObject(dataRateValue, lastPartialPacket, txTimeUSJson)
    //         break;
    //     case 'EU868':
    //         loopJsonObject(dataRateValue, lastPartialPacket, txTimeEUJson)
    //         break;

    //     default: // Do nothing, should be unreachable
    // }

    let x = null;
    if (regionValue == "US915") {
      Object.keys(txTimeUSJson).forEach((item) => {
        if (dataRateValue === item) {
          for (let i = 0; i < txTimeUSJson[item].length; i++) {
            // console.log(txTimeUSJson[item][i])
            if (
              txTimeUSJson[item][i].samplesPerPacketVal === lastPartialPacket
            ) {
              x = txTimeUSJson[item][i].dataRateVal;
            }
          }
        }
      });
    }
    if (regionValue == "AU915") {
      Object.keys(txTimeAUJson).forEach((item) => {
        if (dataRateValue === item) {
          for (let i = 0; i < txTimeAUJson[item].length; i++) {
            // console.log(txTimeUSJson[item][i])
            if (
              txTimeAUJson[item][i].samplesPerPacketVal === lastPartialPacket
            ) {
              x = txTimeAUJson[item][i].dataRateVal;
            }
          }
        }
      });
    }
    if (regionValue == "EU868") {
      Object.keys(txTimeEUJson).forEach((item) => {
        if (dataRateValue === item) {
          for (let i = 0; i < txTimeEUJson[item].length; i++) {
            // console.log(txTimeEUJson[item][i])
            if (
              txTimeEUJson[item][i].samplesPerPacketVal === lastPartialPacket
            ) {
              x = txTimeEUJson[item][i].dataRateVal;
            }
          }
        }
      });
    }

    if (regionValue == "AS923") {
      Object.keys(txTimeASJson).forEach((item) => {
        if (dataRateValue === item) {
          for (let i = 0; i < txTimeASJson[item].length; i++) {
            // console.log(txTimeEUJson[item][i])
            if (
              txTimeASJson[item][i].samplesPerPacketVal === lastPartialPacket
            ) {
              x = txTimeASJson[item][i].dataRateVal;
            }
          }
        }
      });
    }

    localStorage.setItem("txTimeValueLocal", x);
    // handleCalculation(shelfDays, temperature, temperaturePeriod, temperatureReporting, x)
    setTxTimeValue(x);

    let joinRequest;
    let boot;
    let configRequest;
    let configRequestACK;
    let deviceStatusReport;
    let sleep;

    let healthCalc;
    if (regionValue == "US915" || regionValue == "AU915") {
      joinRequest = 98.9;
      boot = 139.34;
      configRequest = 461.65;
      configRequestACK = 225.27;
      deviceStatusReport = 103.02;
      sleep = 95.04;
      if (shelfValue <= 19) {
        healthCalc = 10 * 46.45;
        setHealth(healthCalc);
      } else {
        healthCalc = (parseInt(shelfValue) - 4) * 46.45;
        setHealth(healthCalc);
      }
    }

    if (regionValue == "EU868") {
      joinRequest = 57.33;
      boot = 83.77;
      configRequest = 276.07;
      configRequestACK = 112.58;
      deviceStatusReport = 60.23;
      sleep = 95.04;
      if (shelfValue <= 19) {
        healthCalc = 10 * 27.92;
        setHealth(healthCalc);
      } else {
        healthCalc = (parseInt(shelfValue) - 4) * 27.92;
        setHealth(healthCalc);
      }
    }
    if (regionValue == "AS923") {
      joinRequest = 79.8;
      boot = 113.94;
      configRequest = 376.63;
      configRequestACK = 159.99;
      deviceStatusReport = 82.85;
      sleep = 95.04;
      if (shelfValue <= 19) {
        healthCalc = 10 * 37.98;
        setHealth(healthCalc);
      } else {
        healthCalc = (parseInt(shelfValue) - 4) * 37.98;
        setHealth(healthCalc);
      }
    }

    let s1CheckCalc = (parseInt(shelfValue) * 2880 * 0.052).toFixed(2);
    sets1Check(s1CheckCalc);

    let sleepCharge = ((parseInt(shelfValue) + 1) * 95.04).toFixed(2);
    setSleep(sleepCharge);

    let oneTimeChargeCalc =
      parseFloat(joinRequest) +
      parseFloat(boot) +
      parseFloat(configRequest) +
      parseFloat(configRequestACK) +
      parseFloat(healthCalc) +
      parseFloat(s1CheckCalc) +
      parseFloat(sleepCharge);
    setOneTimeCharge(oneTimeChargeCalc);

    let temperatureSamplingPerDayCalc = (1440 / tempPeriodValue) * 0.8075;
    setTemperatureSamplingPerDay(temperatureSamplingPerDayCalc);

    let fullPacketReportingChargeCalc = Number(
      (fullPacket * fullPacketCharge).toFixed(2)
    );
    setFullPacketReportingCharge(fullPacketReportingChargeCalc);
    let partialPacketReportingChargeCalc;
    if (regionValue == "US915" || regionValue == "AU915") {
      partialPacketReportingChargeCalc = Number(
        (
          (packetDataReport - fullPacket) *
          (parseFloat(x) * 118.7 + 7.4953)
        ).toFixed(2)
      );
    }

    if (regionValue == "EU868") {
      partialPacketReportingChargeCalc = Number(
        (
          (packetDataReport - fullPacket) *
          (parseFloat(x) * 61.7 + 7.4953)
        ).toFixed(2)
      );
    }

    if (regionValue == "AS923") {
      partialPacketReportingChargeCalc = Number(
        (
          (packetDataReport - fullPacket) *
          (parseFloat(x) * 91.5 + 7.4953)
        ).toFixed(2)
      );
    }
    setPartialPacketReportingCharge(partialPacketReportingChargeCalc);

    let temperatureReportingChargePerDayCalc = Number(
      (
        (1440 / tempReportingValue) *
        (parseFloat(fullPacketReportingChargeCalc) +
          parseFloat(partialPacketReportingChargeCalc))
      ).toFixed(2)
    );
    setTemperatureReportingChargePerDay(temperatureReportingChargePerDayCalc);

    let temperatureDataRecoveryCalc =
      Math.ceil(0.02 * (1440 / tempReportingValue)) *
      (fullPacketReportingChargeCalc + partialPacketReportingChargeCalc);
    setTemperatureDataRecovery(temperatureDataRecoveryCalc);

    let dailyChargeConsumptionCalc =
      parseFloat(temperatureSamplingPerDayCalc) +
      parseFloat(temperatureReportingChargePerDayCalc) +
      parseFloat(temperatureDataRecoveryCalc) +
      deviceStatusReport +
      sleep;
    setDailyChargeConsumption(dailyChargeConsumptionCalc);

    let normalCapacity = 600;
    let finalCapacityCalc;

    if (tempValue > 0) {
      finalCapacityCalc = parseInt(normalCapacity) * 0.98;
    } else if (tempValue > -10 && tempValue <= 0) {
      finalCapacityCalc = parseInt(normalCapacity) * 0.8;
    } else if (tempValue > -20 && tempValue <= -10) {
      finalCapacityCalc = parseInt(normalCapacity) * 0.65;
    } else if (tempValue <= -20) {
      finalCapacityCalc = parseInt(normalCapacity) * 0.4;
    }
    setFinalCapacity(finalCapacityCalc);

    let finalCalc = parseInt(
      (parseInt(finalCapacityCalc) - parseFloat(oneTimeChargeCalc) / 3600) /
        (parseFloat(dailyChargeConsumptionCalc) / 3600)
    );
    if (finalCalc < 0) {
      setFinalResult(0);
    } else {
      setFinalResult(finalCalc);
    }
  }

  return (
    <div className="container" style={{ marginTop: 150 }}>
   
        <h2 style={{ textAlign: "center" }}>NanoTag Battery Life Estimator</h2>
        <p style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
          This battery life estimator tool is for use with the NanoTag Real Time
          only (NanoTag Closed Loop is not compatible with this tool)
        </p>
        {/* 
            <div onClick={() => navigate('/')} style={{ display: 'flex',  }}>
                <img src={logo} style={{ maxWidth: '15%' }} />
            </div> */}
        {/*<div className="row justify-content-center mt-2">

            </div>
            <div className="row justify-content-center mt-5 mb-4">
                <div className="col-md-10" style={{
                    display: "flex",
                    justifyContent: "center"
                }} >
                    <h2 className={classes.bigText}> {
                        loading ? "Calculating ..." : "NanoTag Battery Calculator"}</h2>
                </div>
            </div>*/}

        {/* <h6 style={{ marginTop: 20 }}>samples Per Data Report: {samplesPerDataReport}</h6>
                <h6>packets Per Datareport: {packetsPerDataReport}</h6>
                <h6>full PacketCount: {fullPacketCount}</h6>
                <h6>last Partial Packet Size: {lastPartialPacketSize}</h6>
                <h6>health: {health}</h6>
                <h6>s1Check: {s1Check}</h6>
                <h6>sleep: {sleep}</h6>
                <h6>one time charge: {oneTimeCharge}</h6>
                <h6>temperature sampling per day: {temperatureSamplingPerDay}</h6>
                <h6>full Packet Reporting Charge: {fullPacketReportingCharge}</h6>
                <h6>partial Packet Reporting Charge: {partialPacketReportingCharge}</h6>
                <h6>temperature reporting charge per day: {temperatureReportingChargePerDay}</h6>
                <h6>temperatureDataRecovery: {temperatureDataRecovery}</h6>
                <h6>dailyChargeConsumption: {dailyChargeConsumption}</h6>
                <h6>finalCapacity: {finalCapacity}</h6>
                <h6>txTimeValue: {txTimeValue}</h6>
                <h6>datarate: {dataRate}</h6> */}
   <div className={classes.mainDiv}>
        {loading ? (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ReactLoading type={"bars"} color={"#2aace3"} />
          </div>
        ) : (
          <Container style={{ marginLeft: 20 }}>
            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                <TextField
                  onChange={(e) => {
                    setShelfDays(e.target.value);

                    localStorage.setItem("shelfDays", e.target.value);
                    // console.log("e.target.value", e.target.value)
                    handleCalculation(
                      e.target.value,
                      temperature,
                      temperaturePeriod,
                      temperatureReporting,
                      txTimeValue,
                      dataRate,
                      region
                    );
                    if (
                      e.target.value == "" ||
                      e.target.value < 0 ||
                      e.target.value > 1096
                    ) {
                      setShelfDaysError(true);
                    } else {
                      setShelfDaysError(false);
                    }
                  }}
                  label="Shelf life (days)"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                      right: -3,
                    },
                  }}
                  // sx={{
                  //     m: 1, width: '70ch', "& label": {
                  //         fontFamily: 'OpenSans',
                  //     }
                  // }}

                  type="number"
                  required
                  error={shelfDaysError}
                  value={shelfDays}
                />
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={8000}
                  title="Number of days the NanoTag has been with a customer prior to activation."
                >
                  <IconButton>
                    <QuestionMark />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                <TextField
                  required
                  label="Temperature (ºC)"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                  type="number"
                  onChange={(e) => {
                    setTemperature(e.target.value);
                    // temp = e.target.value
                    localStorage.setItem("temperature", e.target.value);
                    handleCalculation(
                      shelfDays,
                      e.target.value,
                      temperaturePeriod,
                      temperatureReporting,
                      txTimeValue,
                      dataRate,
                      region
                    );
                    if (
                      e.target.value == "" ||
                      e.target.value < -40 ||
                      e.target.value > 100
                    ) {
                      setTemperatureError(true);
                    } else {
                      setTemperatureError(false);
                    }
                  }}
                  error={temperatureError}
                  value={temperature}
                />
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={8000}
                  title="Operating temperature."
                >
                  <IconButton>
                    <QuestionMark />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {/* <Grid container alignItems="center"
                            className={classes.displayFlex}
                        > */}
            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                <TextField
                  required
                  key={"textfield1"}
                  variant={"outlined"}
                  margin={"dense"}
                  label="Sampling Period (minutes)"
                  // id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                      right: -3,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                  // InputProps={{
                  //     startAdornment: <InputAdornment position="start">(minutes)</InputAdornment>,
                  // }}
                  type="number"
                  onChange={(e) => {
                    setTemperaturePeriod(e.target.value);
                    localStorage.setItem("temperaturePeriod", e.target.value);
                    handleCalculation(
                      shelfDays,
                      temperature,
                      e.target.value,
                      temperatureReporting,
                      txTimeValue,
                      dataRate,
                      region
                    );
                    if (
                      e.target.value == "" ||
                      e.target.value < 0.166 ||
                      e.target.value > 65535
                    ) {
                      setTemperaturePeriodError(true);
                    } else if (
                      e.target.value > parseInt(temperatureReporting)
                    ) {
                      setTemperatureReportingError(true);
                      setTemperaturePeriodError(true);
                    } else {
                      setTemperatureReportingError(false);
                      setTemperaturePeriodError(false);
                    }
                  }}
                  error={temperaturePeriodError}
                  value={temperaturePeriod}
                />
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={8000}
                  title="Frequency that the NanoTag records a temperature reading (Minimum of 10 seconds)."
                >
                  <IconButton>
                    <QuestionMark />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                <TextField
                  required
                  label="Reporting Period (minutes)"
                  id="outlined-start-adornment"
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                  type="number"
                  onChange={(e) => {
                    setTemperatureReporting(e.target.value);
                    localStorage.setItem(
                      "temperatureReporting",
                      e.target.value
                    );
                    handleCalculation(
                      shelfDays,
                      temperature,
                      temperaturePeriod,
                      e.target.value,
                      txTimeValue,
                      dataRate,
                      region
                    );
                    if (
                      e.target.value == "" ||
                      (region == "US915" ||
                      region == "AU915" ||
                      region == "AS923"
                        ? e.target.value < 0.5
                        : e.target.value < 2) ||
                      e.target.value > 65535
                    ) {
                      setTemperatureReportingError(true);
                    } else if (e.target.value < parseInt(temperaturePeriod)) {
                      setTemperatureReportingError(true);
                      setTemperaturePeriodError(true);
                    } else {
                      setTemperatureReportingError(false);
                      setTemperaturePeriodError(false);
                    }
                  }}
                  error={temperatureReportingError}
                  value={temperatureReporting}
                />
                {region == "US915" || region == "AU915" || region == "AS923" ? (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={8000}
                    title="Frequency that the NanoTag transmits a temperature reading (Minimum of 30 seconds)."
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={8000}
                    title="Frequency that the NanoTag transmits a temperature reading (Minimum of 2 minutes)."
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            {/* <div className="col-lg-5 d-flex container" style={{ alignItems: "center" }}>
                            <h5 className={classes.errorText}>Temperature reporting period should be greater than temperate sampling period</h5>
                        </div> */}

            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                {/* <TextField
                                        disabled
                                        id="outlined-disabled"
                                        label="Region"
                                        sx={{
                                            m: 1, width: '45ch', "& label": {
                                                fontFamily: 'OpenSans',
                                            }
                                        }}
                                        defaultValue="US915"

                                    /> */}
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Region"
                  value={region}
                  onChange={(e) => {
                    setRegion(e.target.value);
                    // temp = e.target.value
                    localStorage.setItem("region", e.target.value);
                    handleCalculation(
                      shelfDays,
                      temperature,
                      temperaturePeriod,
                      temperatureReporting,
                      txTimeValue,
                      dataRate,
                      e.target.value
                    );
                    if (
                      (e.target.value == "US915" ||
                        e.target.value == "AU915") &&
                      temperatureReporting < 0.5
                    ) {
                      setTemperatureReportingError(true);
                    } else if (
                      e.target.value == "EU868" &&
                      temperatureReporting < 2
                    ) {
                      setTemperatureReportingError(true);
                    } else {
                      setTemperatureReportingError(false);
                    }
                  }}
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                >
                  {allRegions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={8000}
                  title="Operating region of the NanoTag. See the note below the form for more information."
                >
                  <IconButton>
                    <QuestionMark />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {/* <div className="row justify-content-center mt-2">
                            <div className="col-md-10" style={{
                                display: "flex",
                                justifyContent: "center"
                            }} > */}

            <div className={classes.textFieldView}>
              <div
                className="col-lg-5 d-flex container"
                style={{ alignItems: "center" }}
              >
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Data Rate"
                  value={dataRate}
                  onChange={handleChangeDataRate}
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& label": {
                      // fontFamily: 'OpenSans',
                    },
                  }}
                >
                  {region == "US915"
                    ? allDataRate.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    : allDataRateEU.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                </TextField>

                {region == "US915" ? (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={8000}
                    title="DR0 provides the longest range, but has the lowest bandwidth. DR2 provides the shortest range, but has the highest bandwidth. US915 supports DR0/DR1/DR2."
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={8000}
                    title="DR2 provides the longest range, but has the lowest bandwidth. DR4 provides the shortest range, but has the highest bandwidth. EU868, AU915 and AS923 support DR2, DR3, DR4."
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>

            <div
              className="justify-content-center mt-3"
              style={{ marginRight: 10, marginBottom: 70 }}
            >
              <div
                className="col-md-12"
                style={{
                  // display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  <>
                    <h4 className={classes.smallText}>
                      Expected Lifetime:{" "}
                      {Number.isNaN(finalResult) ||
                      shelfDaysError == true ||
                      temperatureError == true ||
                      temperaturePeriodError == true ||
                      temperatureReportingError == true ||
                      dataRate == "" ||
                      dataRate == null
                        ? "N/A"
                        : `${finalResult} days`}
                    </h4>
                    <h3 className={classes.disclaimerText}>
                      Disclaimer: This is only an estimate. Many factors can
                      affect battery life.
                    </h3>
                    <h3 className={classes.disclaimerText}>
                      To determine which Region to select, please refer to the
                      "Quick cross reference table" starting on Page 8 of the{" "}
                      <a
                        style={{ color: "#2aace3" }}
                        href="https://lora-alliance.org/resource_hub/lorawan-regional-parameters-v1-0-3reva/"
                        target="_blank"
                      >
                        LoRaWAN® Regional Parameters document.
                      </a>
                    </h3>
                  </>
                }
              </div>
            </div>
          </Container>
        )}
      </div>
    </div>
  );
};

export default Calculator;
