import React, { useEffect, useState } from "react";
import image1 from "../assets/image/landing-1/white-shape-top.svg";
import comp1 from "../assets/image/c-1.jpeg";
import comp2 from "../assets/image/c-2.jpeg";
import comp3 from "../assets/image/c-3.jpeg";
import comp4 from "../assets/image/c-4.jpeg";
import nanoAni from "../assets/image/nano-ani.svg";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  const slider = React.useRef(null);

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          centerPadding: "10%",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "20px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const [activeArrow, setActiveArrow] = useState(0);

  const handleArrowClick = (index) => {
    if (index == 0) {
      slider?.current?.slickPrev();
    } else {
      slider?.current?.slickNext();
    }
    setActiveArrow(index);
  };

  return (
    <div>
      {/* Hero Area */}
      <div className="hero-area-l1  position-relative background-property">
        {/* <div className="bg-shape">
              <img src="../assets/image/BG-shape.png" alt="">
            </div> */}
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 order-lg-1 order-1">
              <div className="content">
                <h2>
                  An open hardware ecosystem with enterprise grade plug and play
                  sensor protocols.
                </h2>
                {/* <p>Autonomous source-to-store temperature monitoring powered by a new sensor standard</p> */}
                <div className="l1-create-acc-btn">
                  <a href="/nanotag" className="btn btn-style-02">
                    NanoTag<i className="fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* globe */}
            <div className="offset-xxl-0 col-xxl-6 col-xl-6 col-lg-6 col-md-8 order-lg-1 order-0">
              <div className="logo-title img-nano-title">
                <img
                  src={require("../assets/image/nano/album/1.jpeg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Area-1 */}
      <div className="content-are-l1-1 bg-grey" id="ntss-st">
        <div className="curve-image-l1">
          <img src={image1} alt="image" className="w-100" />
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-10 order-1 order-lg-1 d-flex align-items-center">
              <div className="content-box-l1-1 section__heading">
                <div className="counter-js">
                  <h2>The NanoThings Protocols</h2>

                  <p>
                    The most advanced sensor protocols in the LoRa ecosystem.
                  </p>
                  {/* <div id="countdown">
                        <ul>
                          <li><span id="days"></span>days</li>
                          <li><span id="hours"></span>Hours</li>
                          <li><span id="minutes"></span>Minutes</li>
                          <li><span id="seconds"></span>Seconds</li>
                        </ul>
                      </div> */}
                </div>

                {/* <div className="l1-get-start-btn">
                      <a href="javascript:;"  data-bs-toggle="modal" data-bs-target="#modal-signup" className="btn btn-style-02">Join the waitlist <i className="fas fa-angle-right"></i></a>
                    </div> */}
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 order-2 ps-5 pt-5">
              <div className="content-box-2-l1 section__heading">
                <ul className="list-unstyled number-content-box-l1">
                  <li className="d-flex align-items-start">
                    <div className="number">
                      <span>1</span>
                    </div>
                    <div className="content">
                      <a href="#" style={{ textDecoration: "none" }}>
                        <h4>​​NanoThings Beacon</h4>
                      </a>
                      <p>
                        The NanoThings Beacon edge protocol was developed for
                        indoor asset location monitoring. This simple yet
                        effective protocol leverages frequent uplinks as RSSI
                        beacons to determine the approximate location of the
                        device in relation to a gateway/s. This protocol
                        requires onsite gateway installation.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start">
                    <div className="number number--2">
                      <span>2</span>
                    </div>
                    <div className="content">
                      <a href="#" style={{ textDecoration: "none" }}>
                        <h4>NanoThings Move</h4>
                      </a>
                      <p>
                        NanoThings Move is a real time edge protocol
                        specifically designed to support asset tracking
                        applications over the Helium Network. The protocol can
                        be used for other networks, but it is not Sidewalk
                        compatible.
                      </p>
                    </div>
                  </li>

                  <li className="d-flex align-items-start">
                    <div className="number number--2">
                      <span>3</span>
                    </div>
                    <div className="content">
                      <a href="#" style={{ textDecoration: "none" }}>
                        <h4>NanoThings Cold</h4>
                      </a>
                      <p>
                        NanoThings Cold is an edge protocol that powers
                        temperature monitoring devices used for cold chain
                        monitoring. This protocol can be used to power
                        in-transit and stationary temperature monitoring
                        applications.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-start">
                    <div className="number number--2">
                      <span>4</span>
                    </div>
                    <div className="content">
                      <a href="/closed-loop" style={{ textDecoration: "none" }}>
                        <h4>NanoThings Approach</h4>
                      </a>
                      <p>
                        NanoThings Approach is an edge protocol that powers
                        temperature monitoring devices that move between two
                        predetermined locations. This protocol requires a
                        gateway installation at the destination (and preferably
                        at the origin).
                      </p>
                    </div>
                  </li>
                </ul>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area-2-l1 position-relative">
        <div className="container">
          <div className="row justify-content-lg-start justify-content-center align-items-center">
            <div className="offset-xxl-1 col-xxl-4 offset-xl-1 col-xl-5 col-lg-6 col-md-10">
              <div className="content-area-l9-2-image position-relative">
                <div className="main-image svg-img-1">
                  <img className="w-100" src={nanoAni} alt="image" />
                  {/* <Icon/> */}
                </div>
                {/* <div className="shape-image d-none d-sm-block">
                      <img src="../assets/image/landing-9/shape-2.png" alt="image">
                    </div> */}
              </div>
            </div>
            <div className="offset-xxl-1 col-xxl-4 offset-xl-1 col-xl-5 col-lg-6 col-md-10">
              <div className="content section__heading">
                <h2>NanoTag 1.0</h2>
                <p>
                  Thinner than a credit card, mechanically flexible and 50,000
                  transmission battery life, are just a few of the features that
                  make the NanoTag truly unique.
                </p>
                <div className="l1-create-acc-btn">
                  <a href="/nanotag" className="btn btn-style-02">
                    Learn more<i className="fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial Area */}
      <div className="testimonial-area-l2 bg-grey">
        <div className="curve-image-l1">
          <img src={image1} alt="image" className="w-100" />
        </div>
        <div className="container" style={{ marginTop: "5rem" }}>
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="section__heading">
                <h2>Customer Testimonials</h2>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-4">
              <div className="l2-slider-arrow-1 text-end">
                <i
                  className={`prevl2 fas fa-angle-left slick-arrow ${
                    activeArrow === 0 ? "slick-active" : ""
                  }`}
                  onClick={() => {
                    handleArrowClick(0);
                  }}
                ></i>
                <i
                  className={`nextl2 fas fa-angle-right slick-arrow ${
                    activeArrow === 1 ? "slick-active" : ""
                  }`}
                  onClick={() => {
                    handleArrowClick(1);
                  }}
                ></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider
                {...sliderSettings}
                className="testimonial-slider-l2-1"
                ref={slider}
              >
                <div className="single-item focus-reset">
                  <div className="testimonial-l2-single-card">
                    <div className="user-image">
                      <img src={comp1} alt="image" />
                    </div>
                    <div className="content">
                      <p>
                        “The NanoTags have proven to be a great value throughout
                        the entire handling process.”
                      </p>
                    </div>
                    <div className="user-identity align-items-center">
                      <div className="user-details">
                        <h4>Joe Apodaca</h4>
                        <p>Manager, Refrigerated/Frozen Warehouse Services</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-item focus-reset">
                  <div className="testimonial-l2-single-card">
                    <div className="user-image">
                      <img src={comp2} alt="image" />
                    </div>
                    <div className="content">
                      <p>
                        “The NanoThings technology saves us time and gives us
                        the traceability we need to ensure we’re always
                        providing the best service to our customers.”
                      </p>
                    </div>
                    <div className="user-identity d-xs-flex  text-xs-start align-items-center">
                      <div className="user-details">
                        <h4>Sam Spicher</h4>
                        <p>VP Business Development</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-item focus-reset">
                  <div className="testimonial-l2-single-card">
                    <div className="user-image">
                      <img src={comp3} alt="image" />
                    </div>
                    <div className="content">
                      <p>
                        “NanoThings has developed a novel LoRa-based connected
                        temperature and impact detection sensor using printed
                        electronics, enabling end-to-end visibility and
                        traceability in the cold-chain industry, and allowing
                        for increased automation and quality control.”
                      </p>
                    </div>
                    <div className="user-identity d-xs-flex  text-xs-start align-items-center">
                      <div className="user-details">
                        <h4>Patrick van Eijk</h4>
                        <p>Director of IoT Solutions</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-item focus-reset">
                  <div className="testimonial-l2-single-card">
                    <div className="user-image">
                      <img src={comp4} alt="image" />
                    </div>
                    <div className="content">
                      <p>
                        “We’ve integrated our leading Food Safety and Quality
                        Management Supply Chain tools with the NanoThings
                        temperature monitoring solution to provide cutting edge
                        cold chain management and visibility.”
                      </p>
                    </div>
                    <div className="user-identity d-xs-flex  text-xs-start align-items-center">
                      <div className="user-details">
                        <h4>Jez Pile</h4>
                        <p>Director of Cold Chain</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
