import React, { useEffect } from "react";
const PrivacyPolicy = () => {
  return (
    <>
      <div class="inner-terms-banner">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12">
              <div class="section-heading-14 text-center">
                <h2>NanoThings, Inc. Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-lg-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="content section__heading">
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text text-center"
            >
              Last Updated: October 21, 2019
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              This Privacy Policy applies to&nbsp;
              <a href="https://www.nanothingsinc.com/">
                https://www.nanothingsinc.com/&nbsp;
              </a>
              and NanoThings, Inc. products and services.
            </p>
            <br />
            <h4 className="terms-title">What information do we collect?</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We may collect and receive both Personally Identifiable
              Information and Non-Personally Identifiable Information, including
              your name, email address, mailing address, IP address, and other
              information. This information may come from a variety of sources,
              including:
            </p>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b> Information you provide directly to us.</b> We may collect
              information directly from users via our websites. For example, if
              you submit an inquiry via the “Contact Us” form on our site, we
              may collect your name, email address, and other information that
              facilitates our communication with you. Similarly, if you create
              an account on our website, we may collect your name, company,
              email address, and other information that enables the creation of
              your user account.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>
                Information that is collected automatically when you use our
                site.
              </b>
              We may automatically collect information when you use our website.
              For example, when you visit our website, we’ll use cookies to
              enhance the information we have about your preferences and to
              learn about your visit.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>
                Information that is collected automatically from our products.
              </b>
              We may automatically collect information from our products so that
              we can provide our services and ensure that our products are
              functioning properly. For example, our NanoTag may periodically
              transmit a basic status message to us that tells us the battery
              has power and the device is able to transmit data over the
              network. Additionally, any sensors (e.g., thermometers, etc.)
              connected to our products may automatically transmit sensor
              readings to us so that we can enable the ability to track sensor
              readings over time.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>
                Information that is collected when you interact with our
                products.
              </b>
              We may automatically collect information when you interact with
              our products. For example, if you break a magazine band that is
              equipped with a NanoTag, we will automatically receive a signal
              that tells us that the magazine band has been broken.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>Information we receive from third parties. </b>
              We may collect information from third parties, including our
              clients and other sources. For example, a client may provide us
              with a list of mailing addresses so that we can tell our client
              whether someone at that address opened a NanoTag-equipped
              magazine. Clients may also provide us with a list of transit or
              shipping locations so that we can help them associate sensor
              readings with locations and points in time.
            </li>

            <br />
            <h4 className="terms-title">How do we use information?</h4>

            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>To contact you.</b>
              We may use your information to contact you, including by email,
              physical mail, or telephone. For example, if you reach out to us
              via the “Contact Us” form on our website, we may follow up with
              you via email. Additionally, if you give us your contact
              information at a marketing event or promotional website, we may
              use that information to send you periodic updates about NanoThings
              products and services. You can unsubscribe from our commercial
              electronic messages at any time by clicking the “Unsubscribe” link
              in the footer of our commercial email messages.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>To enable our products and services.</b>
              We may use the information we collect to enable the products and
              services our partners depend on us to deliver. For example, if a
              leisure magazine publisher equips their periodical with a NanoTag,
              we may use the data transmitted from that NanoTag to tell the
              publisher whether the periodical was opened. Similarly, if a
              logistics provider equips a NanoTag to a parcel, we may use the
              data transmitted from that NanoTag to tell the logistics provider
              whether their parcel experienced extreme temperatures in transit.
              Additionally, if you register for our website in order to perform
              functions on your employer’s behalf, we may share information
              about your use of our website with your employer.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>To better understand your interests and preferences.</b>
              When we receive your information, either directly or indirectly,
              we may use it to better understand your interests and preferences.
              For example, if an automotive magazine publisher equips their
              periodical with a NanoTag, we may use the information transmitted
              by the NanoTag to better understand how likely you are to engage
              with automotive-related content. If you never open the periodical,
              we may infer that automotive topics are not of interest to you. We
              may also share that information with our partners, like the
              publisher who sent you the magazine or others that may be
              interested to know which types of magazines or messages have high
              rates of engagement.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>To improve our site.</b>
              When you use our site, we may use the information we receive from
              you and other sources to improve our site and make our products
              and services more engaging, useful, and compelling. For example,
              we may use Cookies and other technologies to determine which pages
              of our site users are engaging with most frequently.
            </li>
            <li style={{ color: "#636363", fontWeight: 500, marginTop: 20 }}>
              <b>Information we receive from third parties. </b>
              We may collect information from third parties, including our
              clients and other sources. For example, a client may provide us
              with a list of mailing addresses so that we can tell our client
              whether someone at that address opened a NanoTag-equipped
              magazine. Clients may also provide us with a list of transit or
              shipping locations so that we can help them associate sensor
              readings with locations and points in time.
            </li>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              In addition to the uses described above, we may use or disclose
              information to third parties who provide services to us, and when
              required to by a governmental authority and in other legal
              matters. Finally, in the event of a reorganization, merger, or
              sale we may transfer any and all information we have to the
              relevant third party.
            </p>

            <br />
            <h4 className="terms-title">
              What are your choices about how we use your information?
            </h4>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              If you receive a NanoTag-equipped magazine and you don’t want the
              publisher to know whether you opened it or otherwise interacted
              with the NanoTags in that magazine, you can opt out by going to
              the URL printed on the NanoTag and following the instructions on
              the site. When you opt out, we’ll stop associating your personal
              information with the NanoTags attached to that magazine.
            </p>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We may still use the data transmitted from the NanoTag to build
              anonymized, aggregate reports that don’t identify you. For
              example, we may use the data transmitted from the NanoTag to
              calculate an overall open rate for a particular publisher (e.g.,
              90% of recipients opened the magazine), but neither we nor the
              publisher will associate your personal information with the data
              transmitted from the NanoTag you received.
            </p>

            <br />
            <h4 className="terms-title">
              What about cookies and similar technologies?
            </h4>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              Yes, we use cookies, web beacons, and similar technologies to
              enhance the information we have about your preferences and to
              learn about your visit to our site. A cookie is a piece of
              information that is sent to your browser from a web server and
              stored on your computer’s hard drive. A cookie allows us to
              determine that a visit has occurred within our site, as well as
              which pages were viewed. Cookies are not used to identify you and
              will not collect personal information. You can decide if and how
              your computer will accept cookies by configuring your preferences
              option within your web browser.
            </p>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We use Google Analytics to provide us with report about how many
              unique visitors came to our site, what content they view, and
              which links they may have clicked on to get here.
            </p>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              You may opt out of Google Analytics by using&nbsp;
              <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                this browser add-on.&nbsp;
              </a>
              You may opt out of other cookies and similar technologies by
              visiting&nbsp;
              <a href="http://www.aboutads.info/choices">
                http://www.aboutads.info/choices
              </a>
              .
            </p>

            <br />
            <h4 className="terms-title">How do we protect information?</h4>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We maintain administrative, technical and physical safeguards
              designed to protect the information we receive from you, our
              partners, and third parties. These safeguards vary based on the
              sensitivity of the information that is being collected, used, and
              stored. We are not liable for the illegal acts of third parties
              such as criminal hackers.
            </p>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We retain information for as long as necessary for the purpose for
              which it is collected. To dispose of personal information, we may
              anonymize it, delete it, or take other appropriate steps.
              Information may persist in copies made for backup and business
              continuity purposes for additional time.
            </p>

            <br />

            <h4 className="terms-title">What about changes to this policy?</h4>

            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text mt-2"
            >
              We may make periodic changes to this policy if our practices
              change or we develop new products. Changes to the policy will be
              effective on the date of publication, unless we indicate
              otherwise.
            </p>

            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
