import React, { useEffect } from "react";
import nanoTag from "../../assets/image/use-cases/NanotagImage.png";
import coldChain from "../../assets/image/use-cases/coldchain.png";
import styles from "./CaseStudiesStyle";
const ColdChain = () => {
  return (
    <>
      <div className="sub-page-title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h2 style={styles.title}>
                  Case Study: Cold Chain Rejection Monitoring
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-4">
        <p className="predictive-tag-text">Problem</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                One of the largest seafood companies in North America wanted to
                monitor the temperature of rejected loads to determine if they
                were being erroneously rejected. While the vast majority of
                their customers required temperature monitors on inbound loads,
                they were always removed by the customer upon arrival, and the
                temperature logs were not regularly shared. As a result, the
                seafood company had no visibility into whether a rejected load
                was warranted. With an average cost retail value of between
                $150,000 and $500,000, a rejected load had serious financial
                consequences.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5" style={{ textAlign: "center" }}>
        <img src={nanoTag} style={{ width: 300, height: 300 }} />
      </div>
      <div className="predictive-tag col-xl-4 col-lg-4 col-md-4 marginBottom">
        <p className="predictive-tag-text">Objective & Approach</p>
      </div>
      <div className="mt-3">
        <div className="mt-5 marginBottom">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-9 col-lg-9 col-md-9">
                <div className="content section__heading">
                  <p style={styles.text}>
                    The seafood company hypothesized that a significant
                    percentage of rejected loads were being rejected
                    erroneously. They decided to add NanoTags to all loads going
                    to the customer distribution centers with the most
                    rejections. Since NanoTags automatically report temperature
                    and location data back to the seafood company, they could
                    easily determine if a rejection was warranted per customer
                    policy. Since NanoTags are not removed from loads upon
                    arrival, if a load was rejected, the NanoTag would continue
                    monitoring the load all the way back to the seafood
                    company’s facility. As long as they could verify that the
                    load maintained proper temperature during the entire
                    shipment, even if the load was rejected, it could be
                    salvaged and resold.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="content-img">
                  <img src={coldChain} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-5">
        <p className="predictive-tag-text">Results</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-9 col-lg-9 col-md-9">
            <div className="content section__heading">
              <p style={styles.text}>
                The seafood company was able to receive full trip temperature
                visibility on nearly 100% of loads. Within the first month, they
                were able to salvage two rejected loads.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4  mb-5">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h4 style={styles.bold2}>Upfront Investment</h4>
                    <h4 style={styles.bold}>$1,600</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>Number of Saved Loads:</h5>
                    <h5 style={styles.bold}>2</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>Amount of Savings:</h5>
                    <h5 style={styles.bold}>400,000$</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>Total ROI:</h5>
                    <h5 style={styles.bold}>400,000%</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ColdChain;
