import React, { useEffect, useState } from "react";
import heliumLogo from "../assets/image/partners/HeliumLogo.svg";
import senetLogo from "../assets/image/partners/SenetLogo.png";
import nnncologo from "../assets/image/partners/NNNCoLogo.png";
import whiteShape from "../assets/image/landing-1/white-shape-top.svg";
import senzaryLogo from "../assets/image/partners/SenzaryLogo2.png";
import wmwLogo from "../assets/image/partners/WMWLogo.png";
import objectspectrumLogo from "../assets/image/partners/objectspectrumLogo.png";
import readyWirelessLogo2 from "../assets/image/partners/ReadyWirelessLogo2.png";
import spreadNetworkLogo from "../assets/image/partners/SpreadNetworksLogo.png";
import telusLogo2 from "../assets/image/partners/TelusLogo2.png";
import thisfishLogo from "../assets/image/partners/ThisFishLogo.png";
import calchipLogo from "../assets/image/partners/CalChipLogo.png";
import flexLogo from "../assets/image/partners/FlexLogo.png";
import prelonicLogo from "../assets/image/partners/PrelonicLogo.png";

import Slider from "react-slick";


const EcoSystem = () => {
  const slider = React.useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: <button>Previous</button>, // Replace with your custom arrow component
    nextArrow: <button>Next</button>, // Replace with your custom arrow component
  };
  const [activeArrow, setActiveArrow] = useState(0);

  const handleArrowClick = (index) => {
    if (index == 0) {
      slider?.current?.slickPrev();
    } else {
      slider?.current?.slickNext();
    }
    setActiveArrow(index);
  };
  return (
    <>
      <div className="sub-page-title">
        {/* <canvas id="map"></canvas> */}
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section-heading-14 text-center">
                <h1>Ecosystem</h1>
                <p>
                  Join the NanoThings ecosystem to become a licensed solution
                  provider, distributor, or manufacturer of the NanoTag.{" "}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="l1-get-start-btn row justify-content-center pb-5">
            <a
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#modal-signup"
              className="btn btn-style-02"
            >
              Join our Ecosystem<i className="fas fa-angle-right"></i>
            </a>
          </div> */}
        </div>
      </div>

      <div className="clients-area-l8 pb-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center">
                <h2>Networks</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <p>
                  The NanoTag is LoRa Network Server (LNS) agnostic. However,
                  the networks featured below have passed the NanoTag
                  Certification Program, which guarantees that all NanoTag
                  features will be supported. If you’re looking to leverage an
                  LNS not listed below, it is advisable to request the LNS to
                  complete the NanoTag Certification Program prior to utilizing
                  the LNS.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="nano-slider owl-theme">
        <div className="partner-border">
          <a href="https://www.helium.com/" target="_blank">
            <img
              src={heliumLogo}
              alt=""
              className="partner-logo light-opacity"
            />
          </a>
        </div>
      </div> */}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border">
                  <a href="https://www.helium.com/" target="_blank">
                    <img
                      src={heliumLogo}
                      alt=""
                      className="partner-logo light-opacity"
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <p
                    className="text-center partner-text"
                    style={{ textAlign: "center" }}
                  >
                    The Helium Network is the fastest-growing decentralized
                    wireless network, democratizing access to the internet
                    worldwide. Launched in Austin, Texas, in 2019, the Network
                    has since been deployed in over 73K cities, providing
                    open-sourced and affordable coverage worldwide. IoT and
                    network industry leaders including Actility, Senet, X-TELIA,
                    DISH, Victor, Airly, and One Planet are just a few of the
                    customers using the Helium Network.
                  </p>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    Helium is the first public LoRaWAN network with dense enough
                    coverage to enable track and trace applications utilizing
                    the NanoTag. At $.00001 per transmission, the Helium network
                    is by far the lowest cost LoRaWAN network in the world.{" "}
                  </p>
                </div>
              </Slider>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border2">
                  <a href="https://senetco.com/" target="_blank">
                    <img
                      src={senetLogo}
                      alt=""
                      className="partner-logo light-opacity"
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    Senet is by far the most experienced LoRaWAN network in the
                    U.S. They offer free developer accounts for anyone just
                    getting started. Their best feature is that they roam over
                    the Helium network, so NanoTag applications leveraging Senet
                    get Helium + Senet.{" "}
                  </p>
                </div>
              </Slider>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border2">
                  <a href="https://www.nnnco.com.au/" target="_blank">
                    <img
                      src={nnncologo}
                      alt=""
                      className="partner-logo light-opacity"
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    NNNCo is the only LoRaWAN network we would recommend in
                    Australia (they also have global capabilities). A highly
                    knowledgeable leadership team, a willingness to go above and
                    beyond for customers, and a fully functional front end
                    application makes NNNCo unique among networks.{" "}
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div
        className="content-are-l1-1 bg-grey"
        id="ntss-st"
        style={{ paddingBottom: 0 }}
      >
        <div className="curve-image-l1">
          <img src={whiteShape} alt="image" className="w-100" />
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row justify-content-center">
            <div className="clients-area-l8 pb-lg-5" style={{ paddingTop: 60 }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                    <div className="section__heading text-center">
                      <h2>Application Providers </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-8">
                    <div className="text-center section__heading">
                      <p>
                        These out of the box partners provide generic and/or
                        customizable dashboards for any use case.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-page-title" style={{ paddingTop: 40 }}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border3">
                            <a href="https://www.senzary.com/" target="_blank">
                              <img
                                src={senzaryLogo}
                                alt=""
                                className="partner-logo3 center-logo light-opacity"
                                style={{ marginTop: -10 }}
                              />
                            </a>
                          </div>

                          <div className="partner-border2">
                            <p className="text-center partner-text">
                              Senzary is an Industrial IoT service provider
                              focused on end-to-end, pre-integrated solutions
                              for everyday problems in industrial environments.
                              Senzary's IoTLogIQ platform provides on-demand,
                              real-time, cloud-based data processing, including
                              on device machine learning (ML).
                            </p>
                          </div>

                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              Senzary has an ultra robust platform with a modern
                              interface. They were the first application partner
                              in the NanoThings ecosystem to support 100% of the
                              NanoTag Real Time functionality. Their map UI is
                              by far the best we’ve seen in the space.{" "}
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border2">
                            <a href="https://www.wmw-hub.com/" target="_blank">
                              <img
                                src={wmwLogo}
                                alt=""
                                className="partner-logo-WMW light-opacity"
                              />
                            </a>
                          </div>

                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              Customizable email and SMS alerts, nice charts, a
                              good map UI, and scalability are a few of the many
                              features that make WMW a value add in the
                              NanoThings ecosystem. They have years of
                              experience working with the NanoTag, and their
                              application is highly customizable to meet the
                              needs of any NanoTag application.{" "}
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border2">
                            <a
                              href="https://www.objectspectrum.com"
                              target="_blank"
                            >
                              <img
                                src={objectspectrumLogo}
                                alt=""
                                className="partner-logo-WMW light-opacity"
                              />
                            </a>
                          </div>

                          <div className="partner-border2">
                            <p className="text-center partner-text">
                              ObjectSpectrum is a custom IoT development company
                              with an award-winning Prism™ software suite to
                              design, develop, and deploy solutions that are
                              higher quality and significantly less expensive,
                              with faster speed-to-market than anyone in the
                              space. This enables companies of almost any size
                              to drive business value from custom IoT solutions
                              that are secure, reliable, scalable, and
                              affordable.
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-area-l8 pb-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center">
                <h2>System Integrators</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <p>
                  If you’re in need of help either launching and managing a
                  NanoTag use case, a system integrator can get you up and
                  running with minimal effort.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border4">
                  <a href="https://readywireless.com/" target="_blank">
                    <img
                      src={readyWirelessLogo2}
                      alt=""
                      className="partner-logo5 light-opacity"
                      style={{ marginTop: 5 }}
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <p className="text-center partner-text">
                    Ready Wireless is a leading IoT Managed Services Provider
                    and Systems Integrator. The company is a one-stop-shop that
                    brings networks, devices and portals together to solve
                    weighty problems for business customers. Prepackaged
                    solutions address critical business needs such as asset
                    tracking, contact tracing, distracted driving, and fleet
                    management. Ready’s solutions are customizable and its
                    portals offer clean and powerful management capabilities for
                    Ready’s customers.
                  </p>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    Ready Wireless has all the tools necessary to quickly deploy
                    any NanoTag application. They have partnered with Senet for
                    connectivity and WMW for a customized application. They
                    manage the entire rollout of any solution, so you don’t need
                    to have any technical expertise or resources to get up and
                    running. They also maintain a healthy stock of NanoTags, so
                    there is no lead time (for most use cases).
                  </p>
                </div>
              </Slider>
            </div>
          </div>

          <div className="col-lg-5 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border2">
                  <a href="https://spreadnetworks.io/" target="_blank">
                    <img
                      src={spreadNetworkLogo}
                      alt=""
                      className="partner-logo-telus light-opacity"
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <p className="text-center partner-text2">
                    Spread Networks is a leading enterprise solution provider in
                    the decentralized wireless ecosystem. Leveraging
                    best-in-className, open technologies, we help communities
                    and businesses achieve digital transformation and reclaim
                    ownership over their critical infrastructure systems.
                  </p>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    Spread Networks has deep experience deploying networks for
                    cities and large enterprise customers. It’s a small but
                    scrappy team who are extremely responsive, which is a rare
                    but highly important quality in system integrators. Their
                    knowledge of Helium is a great benefit to anyone looking to
                    leverage Helium with their NanoTag application.{" "}
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div
        className="content-are-l1-1 bg-grey"
        id="ntss-st"
        style={{ paddingBottom: 0 }}
      >
        <div className="curve-image-l1">
          <img src={whiteShape} alt="image" className="w-100" />
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row justify-content-center">
            <div className="clients-area-l8 pb-lg-5" style={{ paddingTop: 60 }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                    <div className="section__heading text-center">
                      <h2>Solution Providers</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-8">
                    <div className="text-center section__heading">
                      <p>
                        If you’re looking for an out of the box solution to a
                        particular problem, a Solution Provider may have just
                        what you’re looking for.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-page-title" style={{ paddingTop: 40 }}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border2">
                            <a href="https://muddyboots.com/" target="_blank">
                              <img
                                src={telusLogo2}
                                alt=""
                                className="partner-logo-telus light-opacity"
                              />
                            </a>
                          </div>
                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              One of the largest telecom companies in North
                              America, and the largest digital agri food
                              solutions business in the world. Telus is our
                              preferred partner for any closed loop cold chain
                              application. They can support cold chain
                              monitoring anywhere in North America, the EU, and
                              Australia. A large stock of NanoTags means that
                              customers can scale without any concern of NanoTag
                              lead times or availability.
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border5">
                            <a href="https://this.fish/" target="_blank">
                              <img
                                src={thisfishLogo}
                                alt=""
                                className="partner-logo5 light-opacity"
                              />
                            </a>
                          </div>

                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              ThisFish has built a tailor-made software platform
                              helping the seafood industry digitize operations.
                              NanoTag support is a new addition to their
                              platform, which enables seafood companies to
                              monitor temperature and track the location of
                              products throughout the supply chain.
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-area-l8 pb-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center">
                <h2>Value Added Distributors </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <p>
                  Since NanoThings does not sell NanoTags to end users, you can
                  purchase them from a licensed distributor below. Distributors
                  in the NanoThings ecosystem will provide manufacturer’s
                  pricing (+3%).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 col-sm-10">
            <div className="text-center">
              <Slider
                {...settings}
                className="owl-theme"
                ref={slider}
                style={{ zIndex: 1000 }}
              >
                <div className="partner-border2">
                  <a href="https://www.calchipconnect.com/" target="_blank">
                    <img
                      src={calchipLogo}
                      alt=""
                      className="partner-logo-telus light-opacity"
                    />
                  </a>
                </div>

                <div className="partner-border2">
                  <h3 className="text-center">What we like</h3>
                  <p className="text-center partner-text">
                    They are the only LoRa/Helium dedicated distributor in the
                    world. Their value added device provisioning service is a
                    huge bonus for non technical customers wanting a device that
                    works right out of the box. They have a huge stock of both
                    NanoTags and Helium gateways, making them a highly
                    dependable partner for any volume needs.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div
        className="content-are-l1-1 bg-grey"
        id="ntss-st"
        style={{ paddingBottom: 0 }}
      >
        <div className="curve-image-l1">
          <img src={whiteShape} alt="image" className="w-100" />
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row justify-content-center">
            <div className="clients-area-l8 pb-lg-5" style={{ paddingTop: 60 }}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                    <div className="section__heading text-center">
                      <h2>Manufacturers</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-xl-8">
                    <div className="text-center section__heading">
                      <p>
                        If you meet minimum order requirements, you can purchase
                        NanoTags directly from a licensed manufacturer. The most
                        unique benefit of working directly with a licensed
                        manufacturer is the ability to produce new versions of
                        the NanoTag under the open license NanoTag agreement.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-page-title" style={{ paddingTop: 40 }}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border2">
                            <a href="https://www.sheldahl.com/" target="_blank">
                              <img
                                src={flexLogo}
                                alt=""
                                className="partner-logo-telus light-opacity"
                                style={{ paddingTop: 10 }}
                              />
                            </a>
                          </div>
                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              Their reputation speaks for itself. Flex is the
                              second largest contract manufacturer in the world.
                              Known for their extremely high quality work and
                              endless scalability, we are proud to have Flex as
                              our primary manufacturer. Because of our open
                              hardware model, Flex is willing to take direct
                              NanoTag orders from customers in the ecosystem who
                              meet the MOQ of 2,500 (MOQ subject to change).
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-10">
                      <div className="text-center">
                        <Slider
                          {...settings}
                          className="owl-theme"
                          ref={slider}
                          style={{ zIndex: 1000 }}
                        >
                          <div className="partner-border3">
                            <a href="http://www.prelonic.com/" target="_blank">
                              <img
                                src={prelonicLogo}
                                alt=""
                                className="partner-logo light-opacity"
                              />
                            </a>
                          </div>

                          <div className="partner-border2">
                            <h3 className="text-center">What we like</h3>
                            <p className="text-center partner-text">
                              Our very first contract manufacturer. They helped
                              us develop the very first NanoTag prototypes, so
                              they understand the NanoTag better than anyone in
                              the ecosystem. While they are limited in scale
                              (8,000 tags per month), they have exceptionally
                              fast turnaround time on orders. Furthermore, they
                              are our strong preference for anyone looking to
                              make prototypes of new NanoTag designs/versions.{" "}
                            </p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="modal-signup"
        tabindex="-1"
        aria-labelledby="modal-signupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="modal-signupLabel">
                Join the Ecosystem
              </h5>
            </div>
            <div className="modal-body">
              <div className="bg-catskill subscribe-newsletter">
                <squarespace:block-field
                  id="blockField6"
                  columns="12"
                  lock-layout="false"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-style-02 __1"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EcoSystem;
