import React, { useEffect } from "react";
import nanoTag from "../../assets/image/use-cases/NanotagImage.png";
import temp from "../../assets/image/use-cases/static-temperature.png";
import styles from "./CaseStudiesStyle";
const StaticTemp = () => {
  return (
    <>
      <div className="sub-page-title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h2 style={styles.title}>
                  Use Case: Static Temperature Monitoring
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-4">
        <p className="predictive-tag-text">Problem</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="content section__heading">
              <p style={styles.text}>
                A major restaurant chain was experiencing a high amount of food
                waste due to fresh product spoilage. In addition, they were not
                compliant with the latest regulations around food safety, namely
                the ability to provide proof of proper temperature control for
                their temperature sensitive food items.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5" style={{ textAlign: "center" }}>
        <img src={nanoTag} style={{ width: 300, height: 300 }} />
      </div>
      <div className="predictive-tag col-xl-4 col-lg-4 col-md-4">
        <p className="predictive-tag-text">Objective & Approach</p>
      </div>
      <div className="mt-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="content section__heading">
                <p style={styles.text}>
                  The restaurant chain had historically used temperature probes
                  for certain refrigerators, but mainly relied on the
                  temperature value displayed on the settings of each
                  refrigerator. They were looking for a connected temperature
                  monitoring system that would not only give them immediate
                  compliance with the latest and expected future regulations,
                  but one that would help them identify the cause of their
                  excess spoilage at certain restaurants.{" "}
                </p>
                <p style={styles.text} className="mt-3">
                  Of the various options they analyzed, they ruled out cellular
                  based monitors immediately due to their high cost. They looked
                  at WiFi and Bluetooth sensors, but both sensor types could not
                  reliably transmit from within a closed walk-in freezer or
                  refrigerator. The only viable option for the restaurant chain
                  was a LoRaWAN-based temperature sensor.{" "}
                </p>
                <p style={styles.text} className="mt-3">
                  Despite having a typical read range of 100X WiFi and
                  Bluetooth, even the initial LoRaWAN sensors they tested had
                  trouble reliably communicating from within their walk-in
                  freezers. That’s when they turned to the NanoTag. The NanoTag
                  is built with a high gain antenna, giving it even better range
                  than most other LoRaWAN sensors.{" "}
                </p>
                <p style={styles.text} className="mt-3">
                  The restaurant chain installed a LoRaWAN gateway in a handful
                  of restaurants (setup time was less than 10 minutes per
                  restaurant). Then they applied NanoTags to the walls of their
                  walk-in freezers and refrigerators, along with some of their
                  smaller refrigerators. The NanoTags were wirelessly programmed
                  to sample the temperature every 10 minutes, and transmit
                  stored samples every hour. Additionally, the restaurant chain
                  set a high temperature threshold of 33°F for freezers and 48°F
                  for refrigerators. If the NanoTag ever reported a value higher
                  than the thresholds, the restaurant chain would receive
                  automatic email and SMS alerts.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="content-img">
                <img className="static-temp-image" src={temp} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="predictive-tag col-xl-3 col-lg-3 col-md-3 mt-5">
        <p className="predictive-tag-text">Results</p>
      </div>
      <div className="mt-3">
        <div className="container pt-lg-5 ">
          <div className="col-xl-9 col-lg-9 col-md-9">
            <div className="content section__heading">
              <p style={styles.text}>
                After a month of testing, the restaurant chain verified that
                they had received 100% of transmissions that the NanoTag sent in
                every single environment the NanoTag had been placed in.
                Furthermore, the NanoTag was the only device tested that
                remained adhered to each surface it was applied to (metal,
                glass, and cardboard were all tested). <br />
                <br /> The restaurant chain was able to quickly correlate the
                highest amount of food waste to the restaurants with the highest
                number of temperature alerts. They immediately implemented
                personnel changes to address the issue, resulting in a
                significant reduction in spoilage. In addition, the NanoThings
                solution gave them FSMA compliance and corporate level
                temperature monitoring visibility for the first time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="mt-4  mb-5">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4 col-md-4 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>First Year Investment: $525</h5>
                    <h5 style={styles.bold2}>Second Year Investment: $173</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>
                      Estimated Spoilage Savings per Store:
                    </h5>
                    <h5 style={styles.bold}>$20,000</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 mt-1">
                <div className="user-identity d-xs-flex center-mobile text-xs-start align-items-center small-tag">
                  <div className="user-details">
                    <h5 style={styles.bold2}>Total ROI:</h5>
                    <h5 style={styles.bold}>20,000%</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StaticTemp;
