import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/image/video.png";
import transport from "../assets/image/ntss/nt-in-t-svg-ani.gif";
import shape2 from "../assets/image/l1/shape2.svg";
import priceShape from "../assets/image/l1/price-black-shape.svg";
import demo from "../assets/image/ntss/s-2_ani.gif";
import demo2 from "../assets/image/ntss/s-1_ani.gif";
import arrowRight from "../assets/image/landing-4/arrow-right1.png";
const ClosedLoop = () => {
  const playVideo = () => {
    document.getElementById("vidwrap").innerHTML =
    '<iframe width="560" height="315" src="https://player.vimeo.com/video/595342592?h=84400953e7" title="Nanothings" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
  };
  const isWebVersion = window.innerWidth >= 768;
  return (
    <>
      <div className="sub-page-title">
        <canvas id="map"></canvas>
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h1>Closed Loop™</h1>
                <p>
                  The world's first solution for source-to-store cold chain
                  monitoring
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div style={{ paddingTop: 100 }} className="video-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inner-about-bg-area">
                <div className="bg-image">
                  <div
                    id="background-video"
                    className="video-container"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                  >
                    <div onClick={playVideo} id="vidwrap">
                      <i className="fas fa-play font-size-7"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clients-area-l8 pb-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
              <div className="section__heading text-center">
                <h2>What is source-to-store cold chain monitoring?</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-lg-5 pt-3">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="content-img">
                <img src={transport} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div className="content section__heading">
                <p>
                  “Source-to-store” refers to the ability to monitor the
                  temperature and location of a product/asset from the top of
                  the cold chain to the very bottom of the cold chain.
                </p>
                <br />
                <p>
                  A <strong>source</strong> generically means wherever the
                  product begins its journey. This could be a field, a
                  processing plant, or a fishing vessel (to name a few).
                </p>
                <br />
                <p>
                  A <strong>store</strong> generically means wherever the
                  product ends its journey. This could be a grocery store, a
                  restaurant, a pharmacy, or a distribution center.
                </p>
                <br />
                <p>
                  What’s important to understand is that the terms source and
                  store are generic and should not be taken literally. Perhaps a
                  customer only requires monitoring on the final leg of the cold
                  chain. In this case, the source may be a distribution center,
                  and the store may be a grocery store.
                </p>

                <div className="border-top mt-3 mb-3"></div>
                <p className="double-content-c3">
                  <strong>
                    NanoThings Closed Loop can be utilized across any type of
                    in-transit temperature monitoring need.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#fff" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
                <h2>
                  NanoThings Closed Loop For Single Leg Cold Chain Monitoring{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={priceShape} alt="" className="w-100" />
        </div>
      </div>

      <div className="service-area-l4-1">
        <div className="container pb-lg-5 pb-3">
          <div className="row justify-content-center mb-lg-5 mb-3">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <p>
                  Single leg cold chain monitoring is the simplest type of
                  in-transit cold chain monitoring. A single leg cold chain has
                  an Origin and a Destination.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-xl-8 mt-lg-5 mg-3">
              <div className="img-box">
                <img src={demo2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <h2>How it Works</h2>
              </div>
            </div>
          </div>
          <div className="row service-l4-card-items">
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Origin</h4>
                  <p>
                    Real time monitoring & real time departure notification{" "}
                  </p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>01</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>In-transit</h4>
                  <p>Data logging mode</p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>02</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Destination</h4>
                  <p>
                    Automatic offload of stored temperature data and real time
                    arrival notification{" "}
                  </p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>03</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex">
              <div className="service-l4-card">
                <div className="content">
                  <h4>Optional</h4>
                  <p>Real time monitoring at Destination</p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>04</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-area-l10-3 bg-blue">
        <div
          className="content-area-l10-2-shape-1"
          style={{ backgroundColor: "#fff" }}
        >
          <img src={shape2} alt="" className="w-100" />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12 pt-lg-5 mt-lg-4">
              <div className="content section__heading text-center">
                <h2>
                  {" "}
                  NanoThings Closed Loop For Multi-Leg Cold Chain Monitoring{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="price-l10-shape" style={{ backgroundColor: "#2aace3" }}>
          <img src={priceShape} alt="" className="w-100" />
        </div>
      </div>

      <div className="service-area-l4-1">
        <div className="container pb-lg-5 pb-3">
          <div className="row justify-content-center mb-lg-5 mb-3">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <p>
                  Multi-leg cold chain monitoring is synonymous with
                  source-to-store monitoring. A multi-leg cold chain effectively
                  has multiple Origins and Destinations.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-xl-10 mt-lg-5 mg-3">
              <div className="img-box __1">
                <img src={demo} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center section__heading">
                <h2>How it Works</h2>
              </div>
            </div>
          </div>
          <div className="row service-l4-card-items">
            <div className="col-xl-25  col-lg-3 col-md-6 d-flex" style={isWebVersion ? { width: '20%' } : {}}>
              <div className="service-l4-card">
                <div className="content">
                  <h4>Origin</h4>
                  <p>Real time monitoring & real time departure time </p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>01</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-25  col-lg-3 col-md-6 d-flex"  style={isWebVersion ? { width: '20%' } : {}}> 
              <div className="service-l4-card">
                <div className="content">
                  <h4>In-transit</h4>
                  <p>Sleep & record mode</p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>02</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-25  col-lg-3 col-md-6 d-flex"  style={isWebVersion ? { width: '20%' } : {}}>
              <div className="service-l4-card">
                <div className="content">
                  <h4>Checkpoint</h4>
                  <p>
                    Automatic offload of stored temperature data & real-time
                    arrival notification, temperature monitoring & departure
                    notification
                  </p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>03</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-25  col-lg-3 col-md-6 d-flex"  style={isWebVersion ? { width: '20%' } : {}}>
              <div className="service-l4-card">
                <div className="content">
                  <h4>In-transit</h4>
                  <p>Sleep & record mode</p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>04</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-25  col-lg-3 col-md-6 d-flex"  style={isWebVersion ? { width: '20%' } : {}}>
              <div className="service-l4-card">
                <div className="content">
                  <h4>Destination</h4>
                  <p>
                    Automatic offload of stored temperature data & real time
                    arrival time{" "}
                  </p>
                </div>
                <div className="number-icon-area d-flex justify-content-between flex-wrap">
                  <div className="icon">
                    <a href="javascript:;">
                      <img src={arrowRight} alt="icon" />
                    </a>
                  </div>
                  <div className="number">
                    <h1>05</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center pt-lg-5 pt-3">
              <p>
                This pattern will repeat itself if there are additional stops.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClosedLoop;
