import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    displayFlex: {
        // display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    bigText: {
         
        marginRight: 55,
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },
    },
    smallText: {
         
        marginRight: 55,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            marginLeft:'35px'
        },
    },

    mainDiv: {
        marginLeft: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '-40px'
        },
    },  
    
    textFieldView: {
   
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    },
    disclaimerText: {
        color: 'grey',
        textAlign: 'center',
        fontSize: 15,
        marginTop: 10,
        marginRight: 70,
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            marginLeft:'35px'
        },
    },
    errorText: {
        color: 'red',
        textAlign: 'center',
        fontSize: 15,
        // marginTop: 10,
        marginRight: 80,
        // left:20,

        [theme.breakpoints.down('md')]: {
            marginRight: 30,
        },
    },
    errorTextContact: {
        color: 'red',
        fontSize: 15,
        // marginTop: 10,
       
     marginLeft:25

        // [theme.breakpoints.down('md')]: {
        //     marginRight: 30,
        // },
    },
}));

export default useStyles