import { useEffect } from "react";
import logo from "../assets/image/logo/logo.svg";
// import '../menu.js'
import realTimePdf from "../assets/pdf/nanotag-real-time-data-sheet.pdf";
import closedDataPdf from "../assets/pdf/nanotag-closed-loop-data-sheet.pdf";
const NavBar = () => {
  const handleFocus = () => {
    const menu = document.querySelector(".menu-block");
    const menuMain = menu.querySelector(".site-menu-main");
    const submenuAll = menu.querySelectorAll(".sub-menu");
    const goBack = menu.querySelector(".go-back");
    const menuTrigger = document.querySelector(".mobile-menu-trigger");
    const closeMenu = menu.querySelector(".mobile-menu-close");
    let subMenu;
    let subMenuArray = [];
    let subMenuTextArray = [];

    function last(array) {
      return array[array.length - 1];
    }
    function last2(array) {
      return array[array.length - 2];
    }

    menuMain.addEventListener("click", (e) => {
      if (!menu.classList.contains("active")) {
        return;
      }
      if (e.target.closest(".nav-item-has-children")) {
        const hasChildren = e.target.closest(".nav-item-has-children");

        showSubMenu(hasChildren);
      }
    });
    goBack.addEventListener("click", () => {
      const lastItem = last(subMenuArray);
      const lastItemText = last2(subMenuTextArray);
      subMenuArray.pop();
      subMenuTextArray.pop();
      if (subMenuArray.length >= 0) {
        document.getElementById(lastItem).style.animation =
          "slideRight 0.5s ease forwards";
        menu.querySelector(".current-menu-title").innerHTML = lastItemText;
        setTimeout(() => {
          document.getElementById(lastItem).classList.remove("active");
        }, 300);
      }
      if (subMenuArray.length == 0) {
        menu.querySelector(".mobile-menu-head").classList.remove("active");
      }
    });
    menuTrigger.addEventListener("click", () => {
      toggleMenu();
    });
    closeMenu.addEventListener("click", () => {
      toggleMenu();
    });
    document.querySelector(".menu-overlay").addEventListener("click", () => {
      toggleMenu();
    });
    function toggleMenu() {
      menu.classList.toggle("active");
      document.querySelector(".menu-overlay").classList.toggle("active");
    }
    function showSubMenu(hasChildren) {
      for (let i = 0; submenuAll.length < i; i++) {
        submenuAll[i].classList.remove("active");
      }
      subMenu = hasChildren.querySelector(".sub-menu");
      subMenuArray.push(subMenu.id);
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle = hasChildren.querySelector(".drop-trigger").textContent;
      subMenuTextArray.push(menuTitle);

      menu.querySelector(".current-menu-title").innerHTML = menuTitle;
      menu.querySelector(".mobile-menu-head").classList.add("active");
    }
    window.onresize = function () {
      if (this.innerWidth > 991) {
        if (menu.classList.contains("active")) {
          toggleMenu();
        }
      }
    };
  };
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    handleFocus(); // Initially call handleFocus

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <header className="sub-page-header site-header site-header--menu-right landing-1-menu site-header--absolute site-header">
      <div className="container-fluid">
        <nav className="navbar site-navbar">
          <div className="brand-logo">
            <a href="/">
              <img src={logo} alt="" className="light-version-logo" />
            </a>
          </div>
          <div className="menu-block-wrapper">
            <div className="menu-overlay" onClick={handleFocus}></div>
            <nav className="menu-block" id="append-menu-header">
              <div className="mobile-menu-head">
                <div className="go-back">
                  <i className="fa fa-angle-left"></i>
                </div>
                <div className="current-menu-title"></div>
                <div className="mobile-menu-close" onClick={handleFocus}>
                  &times;
                </div>
              </div>

              <ul className="site-menu-main">
                {/* <li className="nav-item nav-item-has-children">
                  <a href="/nanotag" className="nav-link-item drop-trigger">
                    NanoTag
                  </a>
                </li> */}

                <li className="buy-section-header nav-item-has-children nav-item">
                  <a
                    href="#"
                    style={{ color: "white" }}
                    className="nav-link-item drop-trigger drop-text-blue"
                  >
                    Buy<i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-1">
                  <li className="sub-menu--item">
                      <a href="/contact">Buy NanoTags</a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/pricing">Protocol Plans</a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/support">Support Plans</a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item nav-item-has-children">
                  <a href="#" className="nav-link-item drop-trigger">
                    NanoTag<i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-1">
                    <li className="sub-menu--item">
                      <a href="/nanotag">NanoTag</a>
                    </li>
                    {/* <li className="sub-menu--item">
                      <a href="/buy">Buy NanoTags</a>
                    </li> */}
                    <li className="sub-menu--item">
                      <a target="_blank" href={realTimePdf}>
                        NanoTag Real Time Data Sheet
                      </a>
                    </li>

                    <li className="sub-menu--item">
                      <a target="_blank" href={closedDataPdf}>
                        NanoTag Closed Loop Data Sheet
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item nav-item-has-children">
                  <a href="#" className="nav-link-item drop-trigger">
                    NanoThings Protocols<i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-1">
                    <li className="sub-menu--item">
                      <a href="/real-time">NanoThings Real Time</a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/closed-loop">NanoThings Closed Loop</a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item nav-item-has-children">
                  <a href="#" className="nav-link-item drop-trigger">
                    Tools & Resources<i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-2">
                    <li className="sub-menu--item">
                      <a href="/nanotag-battery-life-estimator">
                        NanoTag Battery Life Estimator
                      </a>
                    </li>

                    <li className="sub-menu--item">
                      <a href="/nanotag-payload-decoder">
                        NanoTag Payload Decoder
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/configuration-response-downlink-encoder">
                        Configuration Response Downlink Encoder
                      </a>
                    </li>

                    <li className="sub-menu--item">
                      <a target="_blank" href="https://discord.gg/NwWsZWFr">
                        Discord
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a href="/ecosystem" className="nav-link-item">
                    Ecosystem
                  </a>
                </li> */}

                <li className="nav-item nav-item-has-children">
                  <a href="#" className="nav-link-item drop-trigger">
                    Case Studies & Use Cases
                    <i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-3">
                    <li className="sub-menu--item">
                      <a href="/case-study-asset-tracking">
                        Case Study: Asset Tracking
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/case-study-cold-chain">
                        Case Study: Cold Chain Rejection Monitoring
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/case-study-predictive">
                        Case Study: Predictive Maintenance
                      </a>
                    </li>
                    <li className="sub-menu--item">
                      <a href="/use-case-static-temperature">
                        Use Case: Static Temperature Monitoring
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item nav-item-has-children">
                  <a href="#" className="nav-link-item drop-trigger">
                    Company<i className="fas fa-angle-down"></i>
                  </a>
                  <ul className="sub-menu" id="submenu-4">
                    <li className="sub-menu--item">
                      <a href="/about">Who We Are</a>
                    </li>
                    {/* <li className="sub-menu--item">
                      <a href="/support">Support Plans</a>
                    </li> */}
                    {/* <li className="sub-menu--item">
                      <a href="/pricing">Protocol Plans</a>
                    </li> */}
                    {/* <li className="sub-menu--item">
                      <a href="/team">Leadership Team</a>
                    </li> */}
                    {/* <li className="sub-menu--item">
                      <a href="/terms">Terms and Conditions</a>
                    </li> */}
                    <li className="sub-menu--item">
                      <a href="/contact">Contact Us</a>
                    </li>
                    {/* <li className="sub-menu--item">
                      <a href="/faqs">FAQs</a>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          <div className="mobile-menu-trigger" onClick={handleFocus}>
            <span></span>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default NavBar;
