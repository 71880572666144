const styles = {
  title: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  text: {
    color: "#000",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
    fontSize: 25,
  },
  bold2: {
    fontWeight: "bold",
  },
};

export default styles;
